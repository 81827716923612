import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import {isEmpty} from "lodash";
import {Col, Container, Row} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import ProjectDetail from "./projectDetail";
import PersonsInvolved from "./persons-involved";
import OverviewChart from "./overviewChart";
import AttachedFiles from "./attachedFiles";
import Comments from "./comments";

//redux
import {useSelector, useDispatch, connect} from "react-redux";
import {retrieveFightsData} from "../../../store/fights/actions";
import {withTranslation} from "react-i18next";
import {on} from "../../../state-generator/state-generator";
import {CREATE_COMMENT, DELETE_COMMENT} from "../../../store/fights/gen";

const FightsOverview = (props) => {
  //meta title
  document.title = "Fight Overview";
  const params = props.router.params;
  const onCreateComment = on(CREATE_COMMENT);
  const onDeleteComment = on(DELETE_COMMENT);

  const dispatch = useDispatch();

  const {fights, isLoading, fightsSuccess} = useSelector(
    (state) => state.Fights,
  );
  const [fight, setFight] = useState();

  useEffect(() => {
    if (params && params.id) {
      dispatch(retrieveFightsData());
    }
  }, [onCreateComment.isSuccess, onDeleteComment.isSuccess]);

  useEffect(() => {
    if (!isLoading && fightsSuccess) {
      const fight = fights.find((x) => x.fightId === params.id);
      //fight.points = ["point1", "point2"];
      setFight(fight);
    }
  }, [
    isLoading,
    fightsSuccess,
    onCreateComment.isSuccess,
    onDeleteComment.isSuccess,
  ]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Fights" breadcrumbItem="Fight Overview" />

          {!isEmpty(fight) && (
            <>
              <Row>
                <Col lg="8">
                  <ProjectDetail fight={fight} />
                </Col>

                <Col lg="4">
                  <PersonsInvolved personsInvolved={fight.personsInvolved} />
                </Col>
              </Row>

              <Row>
                <Col lg="4">
                  <OverviewChart />
                </Col>

                <Col lg="4">
                  <AttachedFiles files={fight.evidence} />
                </Col>

                <Col lg="4">
                  <Comments
                    comments={fight.comments}
                    t={props.t}
                    fightId={fight.fightId}
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

FightsOverview.propTypes = {
  match: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(connect(null, {})(withTranslation()(FightsOverview)));
