import {
  getWithAuthTokenFn,
  postJsonWithAuthTokenFn,
} from "../../helpers/api_helper";
import {
  DELETE_NOTIFICATIONS_URL,
  RETRIEVE_NOTIFICATIONS_URL,
} from "../../helpers/url_helper";

const TWO_MINUTES = 120000;

export const RETRIEVE_NOTIFICATIONS = "RETRIEVE_NOTIFICATIONS";
export const DELETE_NOTIFICATIONS = "DELETE_NOTIFICATIONS";

const pollRetrieveNotificationsWorker = (payload) => () => {
  return getWithAuthTokenFn(RETRIEVE_NOTIFICATIONS_URL).then((r) => r.data);
};

const deleteNotificationsWorker = (payload) => () => {
  return postJsonWithAuthTokenFn(DELETE_NOTIFICATIONS_URL, payload).then(
    (r) => r.data,
  );
};
export default [
  {
    action: RETRIEVE_NOTIFICATIONS,
    worker: pollRetrieveNotificationsWorker,
    opts: {pollEvery: TWO_MINUTES},
  },
  {action: DELETE_NOTIFICATIONS, worker: deleteNotificationsWorker},
];
