import React, {useEffect, useState} from "react";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PropTypes from "prop-types";
import withRouter from "../../components/Common/withRouter";
import {connect, useDispatch, useSelector} from "react-redux";
import {withTranslation} from "react-i18next";
import {
  ErrorMessage,
  Field,
  FieldArray,
  Formik,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import {createFight, resetCreateFightStatus} from "../../store/fights/actions";
import {errorTopRight, successTopRight} from "../../helpers/notifications";
import {COMMON_STATUS} from "../../helpers/fights_helper";

let resetFormFn;
const FightsCreate = (props) => {
  //meta title
  document.title = "Create New Fight";

  const dispatch = useDispatch();
  const {createFightStatus, error} = useSelector((state) => state.Fights);

  let fightSelected;
  let INITIAL_STATE = {
    fightName: (fightSelected && fightSelected.fightName) || "",
    description: (fightSelected && fightSelected.description) || "",
    status: (fightSelected && fightSelected.status) || "",
    fightImageUrl: (fightSelected && fightSelected.fightImageUrl) || "",
    startDate: (fightSelected && fightSelected.startDate) || "",
    expectedEndDate: (fightSelected && fightSelected.expectedEndDate) || "",
    endDate: (fightSelected && fightSelected.endDate) || "",
    isRemediable: "",
    severity: (fightSelected && fightSelected.severity) || "",
    possibleResolutions: [],
    personsInvolved: [],
    evidence: [],
  };

  const FIGHT_CREATE_SCHEMA = Yup.object({
    fightName: Yup.string()
      .required(props.t("fights-list.enter_fight_name"))
      .max(120, props.t("common.max_characters_number_is") + 120),
    description: Yup.string()
      .required(props.t("fights-list.enter_description"))
      .max(5000, props.t("common.max_characters_number_is") + 5000),
    status: Yup.string().required(props.t("fights-list.enter_status")),
    severity: Yup.string().required(props.t("common.this_is_a_required_field")),
    isRemediable: Yup.string().required(
      props.t("common.this_is_a_required_field"),
    ),
    fightImageUrl: Yup.string().max(
      200000,
      props.t("common.max_characters_number_is") + "200kb",
    ),
    possibleResolutions: Yup.array().of(
      Yup.string()
        .required(props.t("fights.resolution_cant_be_empty"))
        .max(150, props.t("common.max_characters_number_is") + 150),
    ),
    startDate: Yup.date().required(props.t("common.this_is_a_required_field")),
    expectedEndDate: Yup.date().required(
      props.t("common.this_is_a_required_field"),
    ),
    personsInvolved: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required(props.t("common.this_is_a_required_field"))
          .max(30, props.t("common.max_characters_number_is") + 30),
        avatarUrl: Yup.string().max(
          2000,
          props.t("common.max_characters_number_is") + "2000",
        ),
        label1: Yup.string().max(
          10,
          props.t("common.max_characters_number_is") + 10,
        ),
        label2: Yup.string().max(
          10,
          props.t("common.max_characters_number_is") + 10,
        ),
      }),
    ),
    evidence: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required(props.t("common.this_is_a_required_field"))
          .max(30, props.t("common.max_characters_number_is") + 30),
        url: Yup.string()
          .required(props.t("common.this_is_a_required_field"))
          .max(2000, props.t("common.max_characters_number_is") + "2000"),
      }),
    ),
  });

  function submitNewFight(values) {
    const payload = {
      data: values,
    };
    dispatch(createFight(payload));
  }

  useEffect(() => {
    if (createFightStatus === COMMON_STATUS.SUCCESS) {
      if (resetFormFn) {
        successTopRight(
          props.t("fights-create.fight_created_success"),
          props.t("common.success"),
        );
        resetFormFn();
      }
      dispatch(resetCreateFightStatus());
    }
  }, [createFightStatus]);

  useEffect(() => {
    if (createFightStatus === COMMON_STATUS.FAIL) {
      if (error && Object.keys(error).length > 1) {
        let message = props.t("fights-create.fight_created_fail");
        if (
          error.response &&
          error.response.data &&
          error.response.data.details
        ) {
          error.response.data.details.forEach((x) => {
            message +=
              '\n<br/><i class="bx bx-error-circle text-warning fs-5" /> ' +
              x.message;
          });
        }
        errorTopRight(message, props.t("common.error"));
      }
      dispatch(resetCreateFightStatus());
    }
  }, [createFightStatus]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={props.t("fights.fights")}
            breadcrumbItem={props.t("fights.create_new")}
          />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("fights.create_new_fight")}
                  </CardTitle>
                  <Formik
                    initialValues={INITIAL_STATE}
                    validationSchema={FIGHT_CREATE_SCHEMA}
                    onSubmit={(values) => {
                      submitNewFight(values);
                      return false;
                    }}
                  >
                    {({
                      resetForm,
                      handleSubmit,
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }) => (
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          // Call Formik onSubmit
                          resetFormFn = resetForm;
                          handleSubmit(e);
                          return false;
                        }}
                      >
                        <Row className="mb-4">
                          <Label
                            htmlFor="projectname"
                            className="col-form-label col-lg-2"
                          >
                            {props.t("fights.fight_name")}
                          </Label>
                          <Col lg="10">
                            <Field
                              as={Input}
                              name="fightName"
                              placeholder={props.t(
                                "fights-list.enter_fight_name",
                              )}
                              invalid={
                                touched.description && errors.description
                                  ? true
                                  : false
                              }
                            />
                            <ErrorMessage
                              name={`fightName`}
                              component={FormFeedback}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-2"
                          >
                            {props.t("fights-list.fight_description")}
                          </Label>
                          <Col lg="10">
                            <Field
                              name="description"
                              type="textarea"
                              as={Input}
                              placeholder={props.t(
                                "fights-list.enter_description",
                              )}
                              invalid={
                                touched.description && errors.description
                                  ? true
                                  : false
                              }
                            />
                            <ErrorMessage
                              component={FormFeedback}
                              name={`description`}
                            />
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                            htmlFor="projectname"
                            className="col-form-label col-lg-2"
                          >
                            {props.t("fights.fight_image_url")}
                          </Label>
                          <Col lg="10">
                            <Field
                              as={Input}
                              name="fightImageUrl"
                              placeholder={props.t(
                                "fights-list.enter_fight_image_url",
                              )}
                              invalid={
                                touched.fightImageUrl && errors.fightImageUrl
                                  ? true
                                  : false
                              }
                            />
                            <ErrorMessage
                              name={`fightImageUrl`}
                              component={FormFeedback}
                            />
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Col lg="12">
                            <Row>
                              <Col md={4} className="pr-0">
                                <Label className="col-form-label">
                                  <i className="bx bx-calendar me-1 text-primary" />{" "}
                                  {props.t("fights-list.fight_start_date")}
                                </Label>
                                <Field
                                  name="startDate"
                                  type="date"
                                  format="YYYY/MM/DD"
                                  as={Input}
                                  invalid={
                                    touched.startDate && errors.startDate
                                      ? true
                                      : false
                                  }
                                />
                                <ErrorMessage
                                  component={FormFeedback}
                                  name={`startDate`}
                                />
                              </Col>

                              <Col md={4} className="pl-0">
                                <Label className="col-form-label">
                                  <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                                  {props.t("fights.fight_expected_end_date")}
                                </Label>
                                <Field
                                  name="expectedEndDate"
                                  type="date"
                                  as={Input}
                                  format="YYYY/MM/DD"
                                  invalid={
                                    touched.expectedEndDate &&
                                    errors.expectedEndDate
                                      ? true
                                      : false
                                  }
                                />
                                <ErrorMessage
                                  component={FormFeedback}
                                  name={`expectedEndDate`}
                                />
                              </Col>

                              <Col md={4} className="pl-0">
                                <Label className="col-form-label">
                                  <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                                  {props.t("fights-list.fight_end_date")}
                                </Label>
                                <Field
                                  name="endDate"
                                  type="date"
                                  as={Input}
                                  format="YYYY/MM/DD"
                                ></Field>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={4} className="pr-0">
                                <Label className="col-form-label">
                                  <i className="bx bx-message-rounded-dots me-1 text-info" />{" "}
                                  {props.t("common.status")}
                                </Label>
                                <Field
                                  name="status"
                                  type="select"
                                  as={Input}
                                  invalid={
                                    touched.status && errors.status
                                      ? true
                                      : false
                                  }
                                >
                                  <option></option>
                                  <option value="NOT_STARTED">
                                    {props.t("common.not_started")}
                                  </option>
                                  <option value="IN_PROGRESS">
                                    {props.t("common.in_progress")}
                                  </option>
                                  <option value="COMPLETED">
                                    {props.t("common.completed")}
                                  </option>
                                </Field>
                                <ErrorMessage
                                  component={FormFeedback}
                                  name={`status`}
                                />
                              </Col>

                              <Col md={4} className="pl-0">
                                <Label className="col-form-label">
                                  <i className="bx bx-angry me-1 text-danger" />{" "}
                                  {props.t("fights.severity")}
                                </Label>
                                <Field
                                  name="severity"
                                  type="select"
                                  as={Input}
                                  invalid={
                                    touched.severity && errors.severity
                                      ? true
                                      : false
                                  }
                                >
                                  <option></option>
                                  <option value="LOW">
                                    {props.t("common.low")}
                                  </option>
                                  <option value="MEDIUM">
                                    {props.t("common.medium")}
                                  </option>
                                  <option value="HIGH">
                                    {props.t("common.high")}
                                  </option>
                                </Field>
                                <ErrorMessage
                                  component={FormFeedback}
                                  name={`severity`}
                                />
                              </Col>

                              <Col md={4} className="pl-0">
                                <Label className="col-form-label">
                                  <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                                  {props.t("fights.is_remediable")}
                                </Label>
                                <Field
                                  name="isRemediable"
                                  type="select"
                                  as={Input}
                                  invalid={
                                    touched.isRemediable && errors.isRemediable
                                      ? true
                                      : false
                                  }
                                >
                                  <option></option>
                                  <option value="true">
                                    {props.t("common.yes")}
                                  </option>
                                  <option value="false">
                                    {props.t("common.no")}
                                  </option>
                                </Field>
                                <ErrorMessage
                                  component={FormFeedback}
                                  name={`isRemediable`}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <div className="inner-repeater mb-4">
                          <Label>
                            {props.t("fights.possible_resolutions")}
                          </Label>
                          <FieldArray
                            name="possibleResolutions"
                            render={(arrayHelpers) => (
                              <div>
                                {values.possibleResolutions &&
                                values.possibleResolutions.length > 0 ? (
                                  values.possibleResolutions.map(
                                    (resolution, index) => (
                                      <div key={index}>
                                        <Row>
                                          <Col xs={4}>
                                            <Field
                                              as={Input}
                                              name={`possibleResolutions.${index}`}
                                            />
                                            <span className="text-danger">
                                              <ErrorMessage
                                                name={`possibleResolutions.${index}`}
                                              />
                                            </span>
                                          </Col>
                                          <Col>
                                            <Button
                                              type="button"
                                              className="btn-soft-primary"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              -
                                            </Button>
                                            <button
                                              type="button"
                                              className="btn btn-primary "
                                              onClick={() =>
                                                arrayHelpers.insert(index, "")
                                              }
                                            >
                                              +
                                            </button>
                                          </Col>
                                        </Row>
                                      </div>
                                    ),
                                  )
                                ) : (
                                  <Button
                                    onClick={() => arrayHelpers.push("")}
                                    color="success"
                                    className="mt-1"
                                  >
                                    {props.t("fights.add_resolution")}
                                  </Button>
                                )}
                              </div>
                            )}
                          />
                        </div>

                        <div className="inner-repeater mb-4">
                          <Label>{props.t("fights.people_involved")}</Label>
                          <FieldArray name="personsInvolved">
                            {({remove, push}) => (
                              <div>
                                {values.personsInvolved.length > 0 &&
                                  values.personsInvolved.map(
                                    (person, index) => (
                                      <div key={index}>
                                        <Row className="mb-2">
                                          <Col md="4">
                                            <Label>
                                              {props.t("common.name")}
                                            </Label>
                                            <Field
                                              name={`personsInvolved.${index}.name`}
                                              type="text"
                                              as={Input}
                                              className="inner form-control"
                                              placeholder={props.t(
                                                "common.enter_name",
                                              )}
                                            />
                                            <span className="text-danger">
                                              <ErrorMessage
                                                name={`personsInvolved.${index}.name`}
                                              />
                                            </span>
                                          </Col>

                                          <Col md="4">
                                            <Label>
                                              {props.t("fights.label1")}
                                            </Label>
                                            <Field
                                              name={`personsInvolved.${index}.label1`}
                                              type="text"
                                              as={Input}
                                              className="inner form-control"
                                              placeholder={props.t(
                                                "fights-create.enter_label1",
                                              )}
                                            />
                                            <span className="text-danger">
                                              <ErrorMessage
                                                name={`personsInvolved.${index}.label1`}
                                              />
                                            </span>
                                          </Col>

                                          <Col md="4">
                                            <Label>
                                              {props.t("fights.label2")}
                                            </Label>
                                            <Field
                                              name={`personsInvolved.${index}.label2`}
                                              type="text"
                                              as={Input}
                                              className="inner form-control"
                                              placeholder={props.t(
                                                "fights-create.enter_label2",
                                              )}
                                            />
                                            <span className="text-danger">
                                              <ErrorMessage
                                                name={`personsInvolved.${index}.label2`}
                                              />
                                            </span>
                                          </Col>

                                          <Col md="12">
                                            <Label>
                                              {props.t("common.avatar_url")}
                                            </Label>
                                            <Field
                                              name={`personsInvolved.${index}.avatarUrl`}
                                              type="text"
                                              as={Input}
                                              className="inner form-control"
                                              placeholder={props.t(
                                                "common.enter_avatar_url",
                                              )}
                                            />
                                            <span className="text-danger">
                                              <ErrorMessage
                                                name={`personsInvolved.${index}.avatarUrl`}
                                              />
                                            </span>
                                          </Col>
                                        </Row>
                                        <Row className="mb-2">
                                          <Col md="2">
                                            <Button
                                              color="primary"
                                              className="btn-block inner"
                                              id="unknown-btn"
                                              style={{
                                                width: "100%",
                                              }}
                                              onClick={() => {
                                                remove(index);
                                              }}
                                            >
                                              {" "}
                                              {props.t(
                                                "fights.delete_person",
                                              )}{" "}
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    ),
                                  )}
                                <Button
                                  onClick={() => {
                                    push({
                                      name: "",
                                      avatarUrl: "",
                                      label1: "",
                                      label2: "",
                                    });
                                  }}
                                  color="success"
                                  className="mt-1"
                                >
                                  {props.t("fights.add_person")}
                                </Button>
                              </div>
                            )}
                          </FieldArray>
                        </div>

                        <div className="inner-repeater mb-4">
                          <Label>{props.t("fights.evidence")}</Label>
                          <FieldArray name="evidence">
                            {({remove, push}) => (
                              <div>
                                {values.evidence.length > 0 &&
                                  values.evidence.map((person, index) => (
                                    <div key={index}>
                                      <Row>
                                        <Col xs={4}>
                                          <Label>
                                            {props.t("fights.evidence_name")}
                                          </Label>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={8} lg={4}>
                                          <Field
                                            name={`evidence.${index}.name`}
                                            type="text"
                                            as={Input}
                                            className="inner form-control"
                                            placeholder={props.t(
                                              "fights.enter_evidence_name",
                                            )}
                                          />
                                          <span className="text-danger">
                                            <ErrorMessage
                                              name={`evidence.${index}.name`}
                                            />
                                          </span>
                                        </Col>
                                        <Col>
                                          <Button
                                            type="button"
                                            className="btn-soft-primary"
                                            onClick={() => remove(index)}
                                          >
                                            -
                                          </Button>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={4}>
                                          <Label>
                                            {props.t(
                                              "fights.evidence_image_url",
                                            )}
                                          </Label>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={8} lg={4}>
                                          <Field
                                            name={`evidence.${index}.url`}
                                            type="text"
                                            as={Input}
                                            className="inner form-control"
                                            placeholder={props.t(
                                              "fights.enter_evidence_image_url",
                                            )}
                                          />
                                          <span className="text-danger">
                                            <ErrorMessage
                                              name={`evidence.${index}.url`}
                                            />
                                          </span>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                <Button
                                  onClick={() => {
                                    push({
                                      name: "",
                                      url: "",
                                      type: "IMAGE",
                                      size: "",
                                    });
                                  }}
                                  color="success"
                                  className="mt-1"
                                >
                                  {props.t("fights.add_evidence")}
                                </Button>
                              </div>
                            )}
                          </FieldArray>
                        </div>

                        <Row className="justify-content-end">
                          <Col lg="12">
                            <div className="text-left">
                              <button
                                type="submit"
                                className="btn btn-lg btn-primary save-user"
                              >
                                {props.t("fights.create_fight")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

FightsCreate.propTypes = {
  t: PropTypes.any,
};

export default withRouter(connect(null, {})(withTranslation()(FightsCreate)));
