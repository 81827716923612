import React, {useState} from "react";
import PropTypes from "prop-types";
import {Card, CardBody, CardTitle, Modal, Table} from "reactstrap";
import {Link} from "react-router-dom";
import {map} from "lodash";

const AttachedFiles = ({files}) => {
  const [modal_file_view, setmodal_file_view] = useState({
    state: false,
    name: null,
    url: null,
    size: null,
  });
  function tog_center() {
    setmodal_file_view(!modal_file_view.state);
    //  removeBodyCss();
  }

  function resetModal() {
    setmodal_file_view({state: false, name: null, url: null, size: null});
  }

  function setModal(state, name, url, size) {
    setmodal_file_view({state: state, name: name, url: url, size: size});
  }

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Evidence</CardTitle>
        <div className="table-responsive">
          <Table className="table-nowrap align-middle table-hover mb-0">
            <Modal
              isOpen={modal_file_view.state}
              onClosed={() => {
                resetModal();
              }}
              toggle={() => {
                tog_center();
              }}
              centered
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0">
                  Evidence: {modal_file_view.name && modal_file_view.name}
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_file_view(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="">
                  <img
                    src={modal_file_view.url && modal_file_view.url}
                    className="img-fluid"
                    width="100%"
                    height="auto"
                    alt={
                      modal_file_view.name && modal_file_view.name + " image"
                    }
                  />
                </div>
              </div>
            </Modal>

            <tbody>
              {map(files, (file, i) => (
                <tr key={"_file_" + i}>
                  <td style={{width: "45px"}}>
                    <div className="avatar-sm">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                        <i className="bx bxs-file-doc" />
                      </span>
                    </div>
                  </td>
                  <td>
                    <h5 className="font-size-14 mb-1">
                      <Link to="#" className="text-dark">
                        {file.name}
                      </Link>
                    </h5>
                    <small>Size : {file.size}</small>
                  </td>
                  <td>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={() => {
                          tog_center();
                          console.log(modal_file_view);
                          setModal(true, file.name, file.url, file.size);
                        }}
                      >
                        View
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

AttachedFiles.propTypes = {
  files: PropTypes.array,
};

export default AttachedFiles;
