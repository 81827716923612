import React from "react";
import {Link} from "react-router-dom";
import * as moment from "moment";
import {size, map} from "lodash";
import {Field} from "formik";
import {Input} from "reactstrap";
import Avatar from "react-avatar";

const formateDate = (date, format) => {
  const dateFormat = format ? format : "DD MMM Y";
  const date1 = moment(new Date(date)).format(dateFormat);
  return date1;
};
const toLowerCase1 = (str) => {
  return str === "" || str === undefined ? "" : str.toLowerCase();
};

const Name = (cell) => {
  return cell.value ? cell.value : "";
};

const Email = (cell) => {
  return cell.value ? cell.value : "";
};

const Tags = (cell) => {
  return (
    <>
      {map(
        cell.value,
        (tag, index) =>
          index < 2 && (
            <Link
              to="#"
              className="badge badge-soft-primary font-size-11 m-1"
              key={"_skill_" + cell.value + index}
            >
              {tag}
            </Link>
          ),
      )}
      {size(cell.value) > 2 && (
        <Link
          to="#"
          className="badge badge-soft-primary font-size-11 m-1"
          key={"_skill_" + cell.value}
        >
          {size(cell.value) - 1} + more
        </Link>
      )}
    </>
  );
};

const Role = (role, onRoleChanged) => {
  return (
    <>
      {/*{role ? (*/}
      {/*//     <div >*/}
      {/*//       <Input*/}
      {/*//         type="select"*/}
      {/*//         value={role}*/}
      {/*//         onChange={onRoleChanged}*/}
      {/*//         >*/}
      {/*//         <option value="VIEW">View</option>*/}
      {/*//         <option value="EDIT">Edit</option>*/}
      {/*//         <option value="EDIT_INVITE">Admin</option>*/}
      {/*//       </Input>*/}
      {/*//     </div>*/}
      {/*//   ) : (*/}
      {/*//     <div>*/}
      {/*//       N/A*/}
      {/*//     </div>*/}
      {/*//   )}*/}
    </>
  );
};

const Status = (cell) => {
  return cell.value ? cell.value : "";
};

const Img = (cell) => {
  return (
    <>
      {/*{!cell.value ? (*/}
      {/*  <div className="avatar-xs">*/}
      {/*    <span className="avatar-title rounded-circle">*/}
      {/*      /!*{cell.data[0].name.charAt(0)}*!/*/}
      {/*    </span>*/}
      {/*    <Avatar name="john ete" size="150" />*/}
      {/*  </div>*/}
      {/*) : (*/}
      {/*  <div>*/}
      {/*    <Avatar name="hola pep" size="150" />*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  );
};

export {Name, Email, Tags, Role, Img, Status};
