import React, {useEffect, useState} from "react";

import {Row, Col, Card, CardBody} from "reactstrap";
import {Link} from "react-router-dom";

import profileImg from "../../assets/images/profile-img.png";
import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withRouter from "../../components/Common/withRouter";

const WelcomeComp = (props) => {
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [idx, setidx] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setemail(obj.email);
        setidx(obj.uid);
        setAvatarUrl(obj.avatarUrl);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.name);
        setemail(obj.email);
        setidx(obj.uid);
        setAvatarUrl(obj.picture);
      }
    }
  }, [dispatch]);

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">{props.t("Welcome Back!")}</h5>
                <p>{props.t("Fights Dashboard")}</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={avatarUrl}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">{name}</h5>
              <p className="text-muted mb-0 text-truncate"></p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <div className="mt-4">
                  <Link to="/profile" className="btn btn-primary  btn-sm">
                    {props.t("View Profile")}{" "}
                    <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

WelcomeComp.propTypes = {
  t: PropTypes.any,
};

export default withRouter(connect(null, {})(withTranslation()(WelcomeComp)));
