import React from "react";
import {Container, Row, Col} from "reactstrap";
import {Link} from "react-router-dom";

const Section = () => {
  return (
    <React.Fragment>
      <section className="section hero-section bg-ico-hero" id="home">
        <div className="bg-overlay bg-primary"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={5} md={12} sm={12}>
              <div className="text-white-50">
                <h1 className="text-white fw-semibold mb-3 hero-title">
                  Fight Tracker
                </h1>
                <p className="font-size-14">
                  You can finally track all your fights in one place, and
                  explore all the drama happening in the world.
                </p>

                <div className="d-flex flex-wrap gap-2 mt-4">
                  <Link to="/login?type=direct" className="btn btn-success">
                    Join now
                  </Link>
                  <Link to="/login?type=direct" className="btn btn-light">
                    Sign in
                  </Link>
                </div>
              </div>
            </Col>

            <Col lg={7} md={12} sm={12} className="ms-lg-auto">
              {/*<Card className="overflow-hidden mb-0 mt-5 mt-lg-0">*/}
              {/*  <CardHeader className="text-center">*/}
              {/*    <h5 className="mb-0">Fights Dashboard</h5>*/}
              {/*  </CardHeader>*/}
              {/*  <CardBody>*/}
              {/*    <div className="text-left">*/}
              {/*      <img src="https://i.imgur.com/xJNXJWA.png" ></img>*/}
              {/*    </div>*/}
              {/*  </CardBody>*/}
              {/*</Card>*/}
              <div className="text-left">
                <img
                  className="img-fluid mx-auto d-block"
                  alt="dashboard"
                  src="/dash-rotated-3d.png"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
