import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";

//i18n
import {withTranslation} from "react-i18next";
// Redux
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import withRouter from "components/Common/withRouter";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);
  const [name, setname] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setname(obj.username);
        setAvatarUrl(obj.avatarUrl);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setname(obj.name);
        setAvatarUrl(obj.picture);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={avatarUrl}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem>
            {" "}
            <Link to="/profile" className="dropdown-item">
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {props.t("Profile")}{" "}
            </Link>
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  t: PropTypes.any,
};

export default withRouter(connect(null, {})(withTranslation()(ProfileMenu)));
