import React, {useState, useEffect} from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import {useFormik} from "formik";

//redux
import {useSelector, useDispatch, connect} from "react-redux";
import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions

import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";

const UserProfile = (props) => {
  //meta title
  document.title = "Profile";

  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [idx, setidx] = useState(1);
  const [user, setUser] = useState(1);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setemail(obj.email);
        setidx(obj.uid);
        setAvatarUrl(obj.avatarUrl);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setUser(obj);
        setname(obj.username);
        setemail(obj.email);
        setidx(obj.user_id);
        setAvatarUrl(obj.picture);
      }
    }
  }, [dispatch]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || "",
      idx: idx || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required(props.t("Please Enter Your UserName")),
    }),
    onSubmit: (values) => {},
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Fights" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={user.picture}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{user.email}</p>
                        <p className="mb-0">Id: {user.sub}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">{props.t("Properties")}</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Label className="form-label">{props.t("Name")}</Label>
                <Input
                  name="name"
                  className="form-control"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={user.name}
                  disabled={true}
                />
                <div className="form-group">
                  <Label className="form-label">{props.t("Nickname")}</Label>
                  <Input
                    name="username"
                    // value={name}
                    className="form-control"
                    //placeholder={props.t("Enter Username")}
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    //value={validation.values.username || ""}
                    value={user.nickname}
                    disabled={true}
                    // invalid={
                    //   validation.touched.username && validation.errors.username
                    //     ? true
                    //     : false
                    // }
                  />
                  <Label className="form-label">{props.t("Email")}</Label>
                  <Input
                    name="email"
                    className="form-control"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={user.email}
                    disabled={true}
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  {/*<Button type="submit" color="danger">*/}
                  {/*  Update User Name*/}
                  {/*</Button>*/}
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

UserProfile.propTypes = {
  t: PropTypes.any,
};

export default withRouter(connect(null, {})(withTranslation()(UserProfile)));
