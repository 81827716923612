import React from "react";
import UserProfile from "../pages/Authentication/user-profile";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Dashboard from "../pages/Dashboard/index";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import PrivacyPolicy from "../pages/Legal/privacy-policy";
import TermsOfUse from "../pages/Legal/terms-of-use";
import CookiePolicy from "../pages/Legal/cookie-policy";
import FightsList from "../pages/Fights/fights-list";
import FightsCreate from "../pages/Fights/fights-create";
import Landing from "../pages/Landing";
import FightsOverview from "../pages/Fights/FightOverview/fights-overview";
import EmailSubscription from "../pages/EmailSubscription/EmailSubscription";

const authProtectedRoutes = [
  {path: "/dashboard", component: <Dashboard />},

  // //profile
  {path: "/profile", component: <UserProfile />},

  {path: "/fights-list", component: <FightsList />},
  {path: "/fights-create", component: <FightsCreate />},
  {path: "/fights-overview", component: <FightsOverview />},
  {path: "/fights-overview/:id", component: <FightsOverview />},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // {
  //   path: "/",
  //   exact: true,
  //   component: <Navigate to="/dashboard" />,
  // },
];

const publicRoutes = [
  {path: "/login", component: <Login />},
  {path: "/logout", component: <Logout />},
  {path: "/*", component: <Pages404 />},
  {path: "/pages-500", component: <Pages500 />},
  {path: "/privacy", component: <PrivacyPolicy />},
  {path: "/cookie-policy", component: <CookiePolicy />},
  {path: "/terms", component: <TermsOfUse />},
  {path: "/email-subscription", component: <EmailSubscription />},

  {
    path: "/",
    exact: true,
    component: <Landing />,
  },
];

export {authProtectedRoutes, publicRoutes};
