import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Button,
} from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images

//i18n
import {withTranslation} from "react-i18next";
import {
  DELETE_NOTIFICATIONS,
  RETRIEVE_NOTIFICATIONS,
} from "../../../store/notifications/gen";
import {on} from "../../../state-generator/state-generator";
import {useSelector} from "react-redux";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const onRetrieveNotifications = on(RETRIEVE_NOTIFICATIONS);
  const onDeleteNotifications = on(DELETE_NOTIFICATIONS);
  const st = useSelector((st) => st);

  useEffect(() => {
    if (onRetrieveNotifications.isNotTriggeredAtLeastOnce()) {
      onRetrieveNotifications.trigger();
    }
  }, [onRetrieveNotifications.isNotTriggeredAtLeastOnce()]);

  useEffect(() => {
    if (onDeleteNotifications.isSuccess) {
      onRetrieveNotifications.trigger();
    }
  }, [onDeleteNotifications.isSuccess]);

  const deleteNotifications = (notificationIds) => {
    const payload = {notifications: []};
    for (const notificationId of notificationIds) {
      payload.notifications.push({
        notificationId: notificationId,
      });
    }
    onDeleteNotifications.trigger(payload);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">
            {onRetrieveNotifications.data() &&
              onRetrieveNotifications.data().length}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              {/*<div className="col-auto">*/}
              {/*  <a href="#" className="small">*/}
              {/*    View All*/}
              {/*  </a>*/}
              {/*</div>*/}
            </Row>
          </div>

          <SimpleBar style={{height: "230px"}}>
            {onRetrieveNotifications.data() &&
              onRetrieveNotifications.data().map((notification, index) => (
                <Link
                  key={index}
                  to=""
                  className="text-reset notification-item"
                >
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      {notification.iconClass ? (
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className={notification.iconClass} />
                        </span>
                      ) : (
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className="mdi mdi-message-outline" />
                        </span>
                      )}
                    </div>

                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">{notification.title}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{notification.message}</p>
                        <Row>
                          <Col>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" />{" "}
                              {notification.createdAtRelative}{" "}
                            </p>
                          </Col>
                          <Col>
                            <div style={{textAlign: "right"}}>
                              <Button
                                onClick={() =>
                                  deleteNotifications([
                                    notification.notificationId,
                                  ])
                                }
                                className="btn btn-soft-danger btn-sm"
                              >
                                <i className="bx bx-block label-icon "></i>
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
