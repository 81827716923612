import {
  getWithAuthTokenFn,
  postJsonWithAuthTokenFn,
} from "../../helpers/api_helper";
import axios from "axios";
import {
  CREATE_COMMENT_URL,
  DELETE_COMMENT_URL,
  DELETE_SHARE_RESOURCE_URL,
} from "../../helpers/url_helper";

export const GET_RANDO = "GET_RANDO";
export const GET_AMIBO = "GET_AMIBO";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";

const worker = (payload) => {
  console.log(`hi worker, ${payload}`);
  // return  async () => {
  //     return await getWithAuthTokenFn('https://www.amiiboapi.com/api/amijibo/?name=mario').then(r => r.data)
  // }
  return () => {
    return getWithAuthTokenFn("http://localhost:3005/fight/retrieve").then(
      (r) => r.data,
    );
  };
};

const amiboWorker = (payload) => () => {
  console.log("param ", payload);
  return axios
    .get("https://www.amiiboapi.com/api/amiibo/?name=" + payload)
    .then((r) => r.data);
};

const createCommentWorker = (payload) => () => {
  return postJsonWithAuthTokenFn(CREATE_COMMENT_URL, payload).then(
    (r) => r.data,
  );
};

const deleteCommentWorker = (payload) => () => {
  return postJsonWithAuthTokenFn(DELETE_COMMENT_URL, payload).then(
    (r) => r.data,
  );
};

export default [
  {action: GET_RANDO, worker: worker},
  {action: GET_AMIBO, worker: amiboWorker},
  {action: CREATE_COMMENT, worker: createCommentWorker},
  {action: DELETE_COMMENT, worker: deleteCommentWorker},
];
