import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {Col, Row} from "reactstrap";

const LegalHeader = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <Link to="/" className="logo logo-dark">
            <img src="/logo-dark.png" alt={"logo"} height={70} />
          </Link>
          {/*<h4 className="mb-sm-0 font-size-18">*/}
          {/*  <a href={"#"} onClick={() => goBack()}>*/}
          {/*    Go Back*/}
          {/*  </a>*/}
          {/*</h4>*/}
          <div className="page-title-right">
            <ol className="breadcrumb m-0"></ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default LegalHeader;
