import React, {useState} from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

//Import Components
import Accordian from "./accordian";

const FAQs = () => {
  const [activeTab, setactiveTab] = useState("1");

  return (
    <React.Fragment>
      <section className="section" id="faqs">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">FAQs</div>
                <h4>Frequently asked questions</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="vertical-nav">
                <Row>
                  <Col lg="2" sm="4">
                    <Nav pills className="flex-column">
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          setactiveTab("1");
                        }}
                      >
                        <i className="bx bx-help-circle nav-icon d-block mb-2" />
                        <p className="font-weight-bold mb-0">
                          General Questions
                        </p>
                      </NavLink>

                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          setactiveTab("2");
                        }}
                      >
                        <i className="bx bx-receipt nav-icon d-block mb-2" />
                        <p className="font-weight-bold mb-0">Visibility</p>
                      </NavLink>

                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          setactiveTab("3");
                        }}
                      >
                        <i className="bx bx-timer d-block nav-icon mb-2" />
                        <p className="font-weight-bold mb-0">Roadmap</p>
                      </NavLink>
                    </Nav>
                  </Col>
                  <Col lg="10" sm="8">
                    <Card>
                      <CardBody>
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1" id="buy">
                            <h4 className="card-title mb-4">
                              General Questions
                            </h4>

                            {/* accoridan */}
                            <Accordian
                              question1="How much does it cost ?"
                              answer1="Nothing! The service is totally free"
                              question2="Can I register with my email ?"
                              answer2="Currently we only support Registration via social accounts"
                              question3="How do I join ?"
                              answer3="Just click the 'Sign in' button"
                              question4="Do you support other languages ?"
                              answer4="English, German, Russian, Italian and Spanish are supported"
                            />
                          </TabPane>

                          <TabPane tabId="2">
                            <h4 className="card-title mb-4">Visibility</h4>

                            <Accordian
                              question1="Can anyone view my fights ?"
                              answer1="A fight can be made public or private it's your choice!"
                              question2="Can I share my private fights ?"
                              answer2="Yes you can share a fight or an entire list of fights"
                              question3="Is it possible to invite people to edit my fight ?"
                              answer3="Yes you can invite people and assign them roles"
                              question4="What roles can I assign ?"
                              answer4="View, Edit and Admin (Admin can Edit fights and invite others to it)"
                            />
                          </TabPane>

                          <TabPane tabId="3">
                            <h4 className="card-title mb-4">Roadmap</h4>

                            <Accordian
                              question1="When is this site going to be launched ?"
                              answer1="It is already launched"
                              question2="What is next ?"
                              answer2="Many features will be added soon"
                              question3="Why is this website so popular ?"
                              answer3="Because of this great FAQ"
                              question4="Where can I see the plans or suggest my ideas?"
                              answer4="ROADMAP_PLANNING"
                            />
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default FAQs;
