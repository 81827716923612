import React from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import {useAuth0} from "@auth0/auth0-react";

const Logout = () => {
  const {logout} = useAuth0();
  logout({logoutParams: {returnTo: window.location.origin}})
    .then((x) => {
      localStorage.removeItem("authUser");
      console.log(x);
    })
    .catch(console.error);
  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
