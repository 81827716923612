import {errorTopRight} from "./notifications";

const GENERATOR_URL = process.env.REACT_APP_AVATAR_GENERATOR_URL;

export const COMMON_STATUS = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
};
export const convertStatus = (status) => {
  switch (status) {
    case "NOT_STARTED":
      return "Not Started";
    case "IN_PROGRESS":
      return "In Progress";
    case "COMPLETED":
      return "Completed";
    default:
      return "";
  }
};

export const unixToLocalPretty = (dt) => {
  const now = new Date();
  const offsetMs = now.getTimezoneOffset() * 60 * 1000;
  const dateLocal = new Date(new Date(dt * 1000) - offsetMs);
  return dateLocal
    .toISOString()
    .slice(0, 19)
    .replace(/-/g, "/")
    .replace("T", " ");
};

export const isoToLocalPretty = (dt) => {
  const now = new Date();
  const offsetMs = now.getTimezoneOffset() * 60 * 1000;
  const dateLocal = new Date(new Date(dt) - offsetMs);
  return dateLocal
    .toISOString()
    .slice(0, 19)
    .replace(/-/g, "/")
    .replace("T", " ");
};

export const prepareUpdatePayload = (fight) => {
  delete fight.createdAt;
  delete fight.createdBy;
  delete fight.updatedBy;
  delete fight.updatedAt;
  delete fight.owner;
  delete fight.accessControl;
  delete fight.attributes;
  delete fight.comments;
};

export const avatarUrlIfNotPresent = (value, generateString) => {
  if (value) {
    return value;
  }
  return GENERATOR_URL + encodeURIComponent(generateString);
};

export const fightImageIfNotPresent = (fightImageUrl) => {
  return fightImageUrl ? fightImageUrl : "/logo-with-bg.jpg";
};

export const getDomainError = (error) => {
  if (!error) {
    return error;
  }
  if (error?.response?.data?.message) {
    return error.response.data.message;
  } else {
    return error.message;
  }
};

export const getFormattedErrorMessage = (error, t) => {
  const statusCode = error?.response?.status;
  let message = t("common.error_message_generic");
  console.log(error);
  if (!statusCode || !error) {
    return message;
  }

  if (statusCode === 400 && Object.keys(error).length > 1) {
    const badRequestError = error?.response?.data?.details;
    if (badRequestError) {
      message = "";
      badRequestError.forEach((x) => {
        message +=
          '\n<br/><i class="bx bx-error-circle text-warning fs-5" /> ' +
          x.message;
      });
    }
  } else {
    const domainError = error?.response?.data?.message;
    if (domainError) {
      message = domainError;
    }
  }
  return message;
};
