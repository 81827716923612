import React from "react";
import {Container, Row, Col} from "reactstrap";
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={4}></Col>
            <Col md={6}>
              <Link to={"/privacy"} target={"_blank"}>
                Privacy Policy
              </Link>
              <Link to={"/terms"} target={"_blank"} style={{marginLeft: 20}}>
                Terms of Use
              </Link>
              <Link
                to={"/cookie-policy"}
                target={"_blank"}
                style={{marginLeft: 20}}
              >
                Cookie Policy
              </Link>
            </Col>
            <Col md={2}>{new Date().getFullYear()} © Zkr.</Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
