export const GET_TOKEN = "GET_TOKEN";
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";
export const GET_TOKEN_FAIL = "GET_TOKEN_FAIL";

export const STORE_AUTH_TOKEN_FN = "STORE_AUTH_TOKEN_FN";

export const getToken = (fnAsync) => ({
  type: GET_TOKEN,
  payload: fnAsync,
});

export const storeAuthTokenFn = (authTokenFn) => ({
  type: STORE_AUTH_TOKEN_FN,
  payload: authTokenFn,
});
