import {UPDATE_EMAIL_SUBSCRIPTION_URL} from "../../helpers/url_helper";
import axios from "axios";

export const UPDATE_EMAIL_SUBSCRIPTION = "UPDATE_EMAIL_SUBSCRIPTION";

const updateEmailSubscriptionWorker = (payload) => () => {
  return axios.put(UPDATE_EMAIL_SUBSCRIPTION_URL, payload).then((r) => r.data);
};
export default [
  {action: UPDATE_EMAIL_SUBSCRIPTION, worker: updateEmailSubscriptionWorker},
];
