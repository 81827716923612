import React from "react";
import {Container, Row, Col} from "reactstrap";

//swiper
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination, Navigation, Autoplay} from "swiper";
import "../../../../node_modules/swiper/swiper.scss";

const RoadMap = () => {
  const roadmaps = [
    {
      date: "August 9, 2023",
      title: "Idea",
      description:
        "The thought was turned into an OpenAPI, the spec is in the FAQ",
    },
    {
      date: "August 10, 2023",
      title: "Backend",
      description: "The first release with the core functionality",
    },
    {
      date: "August 12, 2023",
      title: "Launch",
      description: "The platform was launched with fights visualization",
    },
    {
      date: "August 21, 2023",
      title: "Big update",
      description: "Registration, Authentication, CRUD, Sharing was added",
    },
    {
      date: "September, 2023",
      title: "Lists",
      description: "Support for creating and sharing lists",
    },
    {
      date: "November, 2023",
      title: "Public Fights",
      description:
        "Visibility of fights can be optionally made public to appear in the global dashboard",
    },
  ];
  return (
    <React.Fragment>
      <section className="section bg-white" id="roadmap">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">Timeline</div>
                <h4>Our Roadmap</h4>
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg="12">
              <div className="hori-timeline">
                <Swiper
                  slidesPerView={1}
                  // spaceBetween={10}
                  navigation
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    678: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 3,
                    },
                    1400: {
                      slidesPerView: 4,
                    },
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  modules={[Pagination, Navigation, Autoplay]}
                  className="owl-carousel owl-theme events navs-carousel"
                  id="timeline-carousel"
                >
                  {/*{roadmaps.map((rm, index) => (*/}
                  {/*  <SwiperSlide key={rm.title} className="item event-list">*/}
                  {/*    <div>*/}
                  {/*      <div className="event-date">*/}
                  {/*        <div className="text-primary mb-1">{rm.date}</div>*/}
                  {/*        <h5 className="mb-4">{rm.title}</h5>*/}
                  {/*      </div>*/}
                  {/*      <div className="event-down-icon">*/}
                  {/*        <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>*/}
                  {/*      </div>*/}

                  {/*      <div className="mt-3 px-3">*/}
                  {/*        <p className="text-muted">*/}
                  {/*          {rm.description}*/}
                  {/*        </p>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </SwiperSlide>*/}
                  {/*))}*/}

                  <SwiperSlide className="item event-list">
                    <div>
                      <div className="event-date">
                        <div className="text-primary mb-1">
                          {roadmaps[0].date}
                        </div>
                        <h5 className="mb-4">{roadmaps[0].title}</h5>
                      </div>
                      <div className="event-down-icon">
                        <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                      </div>

                      <div className="mt-3 px-3">
                        <p className="text-muted">{roadmaps[0].description}</p>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="item event-list">
                    <div>
                      <div className="event-date">
                        <div className="text-primary mb-1">
                          {roadmaps[1].date}
                        </div>
                        <h5 className="mb-4">{roadmaps[1].title}</h5>
                      </div>
                      <div className="event-down-icon">
                        <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                      </div>

                      <div className="mt-3 px-3">
                        <p className="text-muted">{roadmaps[1].description}</p>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="item event-list active">
                    <div>
                      <div className="event-date">
                        <div className="text-primary mb-1">
                          {roadmaps[2].date}
                        </div>
                        <h5 className="mb-4">{roadmaps[2].title}</h5>
                      </div>
                      <div className="event-down-icon">
                        <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                      </div>

                      <div className="mt-3 px-3">
                        <p className="text-muted">{roadmaps[2].description}</p>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="item event-list">
                    <div>
                      <div className="event-date">
                        <div className="text-primary mb-1">
                          {roadmaps[3].date}
                        </div>
                        <h5 className="mb-4">{roadmaps[3].title}</h5>
                      </div>
                      <div className="event-down-icon">
                        <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                      </div>

                      <div className="mt-3 px-3">
                        <p className="text-muted">{roadmaps[3].description}</p>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="item event-list">
                    <div>
                      <div className="event-date">
                        <div className="text-primary mb-1">
                          {roadmaps[4].date}
                        </div>
                        <h5 className="mb-4">{roadmaps[4].title}</h5>
                      </div>
                      <div className="event-down-icon">
                        <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                      </div>

                      <div className="mt-3 px-3">
                        <p className="text-muted">{roadmaps[4].description}</p>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="item event-list">
                    <div>
                      <div className="event-date">
                        <div className="text-primary mb-1">
                          {roadmaps[5].date}
                        </div>
                        <h5 className="mb-4">{roadmaps[5].title}</h5>
                      </div>
                      <div className="event-down-icon">
                        <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                      </div>

                      <div className="mt-3 px-3">
                        <p className="text-muted">{roadmaps[5].description}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default RoadMap;
