export const RETRIEVE_FIGHTS_DATA = "RETRIEVE_FIGHTS_DATA";
export const RETRIEVE_FIGHTS_DATA_SUCCESS = "RETRIEVE_FIGHTS_DATA_SUCCESS";
export const RETRIEVE_FIGHTS_DATA_FAIL = "RETRIEVE_FIGHTS_DATA_FAIL";

export const CREATE_FIGHT = "CREATE_FIGHT";

export const CREATE_FIGHT_SUCCESS = "CREATE_FIGHT_SUCCESS";

export const CREATE_FIGHT_FAIL = "CREATE_FIGHT_FAIL";

export const CREATE_FIGHT_RESET = "CREATE_FIGHT_RESET";

export const UPDATE_FIGHT = "UPDATE_FIGHT";

export const UPDATE_FIGHT_SUCCESS = "UPDATE_FIGHT_SUCCESS";
export const UPDATE_FIGHT_FAIL = "UPDATE_FIGHT_FAIL";

export const DELETE_FIGHT = "DELETE_FIGHT";

export const DELETE_FIGHT_SUCCESS = "DELETE_FIGHT_SUCCESS";

export const DELETE_FIGHT_FAIL = "DELETE_FIGHT_FAIL";

export const retrieveFightsData = () => ({
  type: RETRIEVE_FIGHTS_DATA,
});
export const createFight = (payload) => ({
  type: CREATE_FIGHT,
  payload: payload,
});

export const updateFight = (payload) => ({
  type: UPDATE_FIGHT,
  payload: payload,
});

export const deleteFight = (payload) => ({
  type: DELETE_FIGHT,
  payload: payload,
});

export const resetCreateFightStatus = () => ({
  type: CREATE_FIGHT_RESET,
});
