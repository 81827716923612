import PropTypes from "prop-types";
import React from "react";
import {useAuth0} from "@auth0/auth0-react";

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap";

//redux
import {useSelector, useDispatch} from "react-redux";
import {Link, redirect, useSearchParams} from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import {useFormik} from "formik";

// actions
import {loginUser, socialLogin} from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";
import store from "../../store";
import {FIGHT_SERVICE_RETURNS_UNAUTHORIZED} from "../../store/auth/failures/actions";

const Login = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {loginWithRedirect} = useAuth0();
  const {user, isAuthenticated, isLoading} = useAuth0();

  if (isAuthenticated) {
    console.log(user);
    const principal = {
      email: user.email,
      email_verified: user.email_verified,
      name: user.name,
      nickname: user.nickname,
      picture: user.picture,
      sub: user.sub,
      updated_at: user.updated_at,
    };
    localStorage.setItem("authUser", JSON.stringify(principal));
    //return redirect('/dashboard')
    window.location = "/dashboard";
  }

  //meta title
  document.title = "Login";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    },
  });

  const {error} = useSelector((state) => ({
    error: state.Login.error,
  }));

  // This happens when auth0 returns an error, although the user might still be logged in
  if (searchParams.get("error")) {
    setTimeout(() => {
      window.location = "/logout";
    }, 6000);
  }

  if (searchParams.get("type") && searchParams.get("type") === "direct") {
    loginWithRedirect();
  }
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={12}>
                      {searchParams.get("error") && (
                        <div
                          className="alert-dismissible fade show alert alert-danger alert-dismissible fade show"
                          role="alert"
                        >
                          <i className="mdi mdi-block-helper me-2"></i>
                          {searchParams.get("error")}
                          <br />
                          {searchParams.get("error_description") &&
                            searchParams.get("error_description")}
                          <br />
                        </div>
                      )}
                    </Col>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        {isLoading && (
                          <div role={"status"}>
                            <Spinner animation="border" variant="primary" />
                            Loading...
                          </div>
                        )}
                        <h5 className="text-primary">
                          Welcome To Fight Tracker
                        </h5>
                        <p>Sign in to continue.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      {/*<div className="mb-3">*/}
                      {/*  <Label className="form-label">Email</Label>*/}
                      {/*  <Input*/}
                      {/*    name="email"*/}
                      {/*    className="form-control"*/}
                      {/*    placeholder="Enter email"*/}
                      {/*    type="email"*/}
                      {/*    onChange={validation.handleChange}*/}
                      {/*    onBlur={validation.handleBlur}*/}
                      {/*    value={validation.values.email || ""}*/}
                      {/*    invalid={*/}
                      {/*      validation.touched.email && validation.errors.email*/}
                      {/*        ? true*/}
                      {/*        : false*/}
                      {/*    }*/}
                      {/*  />*/}
                      {/*  {validation.touched.email && validation.errors.email ? (*/}
                      {/*    <FormFeedback type="invalid">*/}
                      {/*      {validation.errors.email}*/}
                      {/*    </FormFeedback>*/}
                      {/*  ) : null}*/}
                      {/*</div>*/}

                      {/*<div className="mb-3">*/}
                      {/*  <Label className="form-label">Password</Label>*/}
                      {/*  <Input*/}
                      {/*    name="password"*/}
                      {/*    value={validation.values.password || ""}*/}
                      {/*    type="password"*/}
                      {/*    placeholder="Enter Password"*/}
                      {/*    onChange={validation.handleChange}*/}
                      {/*    onBlur={validation.handleBlur}*/}
                      {/*    invalid={*/}
                      {/*      validation.touched.password &&*/}
                      {/*      validation.errors.password*/}
                      {/*        ? true*/}
                      {/*        : false*/}
                      {/*    }*/}
                      {/*  />*/}
                      {/*  {validation.touched.password &&*/}
                      {/*  validation.errors.password ? (*/}
                      {/*    <FormFeedback type="invalid">*/}
                      {/*      {validation.errors.password}*/}
                      {/*    </FormFeedback>*/}
                      {/*  ) : null}*/}
                      {/*</div>*/}

                      {/*<div className="form-check">*/}
                      {/*  <input*/}
                      {/*    type="checkbox"*/}
                      {/*    className="form-check-input"*/}
                      {/*    id="customControlInline"*/}
                      {/*  />*/}
                      {/*  <label*/}
                      {/*    className="form-check-label"*/}
                      {/*    htmlFor="customControlInline"*/}
                      {/*  >*/}
                      {/*    Remember me*/}
                      {/*  </label>*/}
                      {/*</div>*/}

                      {!(
                        !error ||
                        (searchParams.get("type") &&
                          searchParams.get("type") === "direct") ||
                        isLoading
                      ) && (
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            onClick={() => loginWithRedirect()}
                          >
                            Log In
                          </button>
                        </div>
                      )}

                      <div className="mt-4 text-center">
                        {/*<h5 className="font-size-14 mb-3">Sign in with</h5>*/}

                        <ul className="list-inline">
                          {/*<li className="list-inline-item">*/}
                          {/*  <Link*/}
                          {/*    to="#"*/}
                          {/*    className="social-list-item bg-primary text-white border-primary"*/}
                          {/*    onClick={(e) => {*/}
                          {/*      e.preventDefault();*/}
                          {/*      socialResponse("facebook");*/}
                          {/*    }}*/}
                          {/*  >*/}
                          {/*    <i className="mdi mdi-facebook" />*/}
                          {/*  </Link>*/}
                          {/*</li>*/}

                          {/*<li className="list-inline-item">*/}
                          {/*  <TwitterLogin*/}
                          {/*    loginUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                          {/*    }*/}
                          {/*    onSuccess={this.twitterResponse}*/}
                          {/*    onFailure={this.onFailure}*/}
                          {/*    requestTokenUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                          {/*    }*/}
                          {/*    showIcon={false}*/}
                          {/*    tag={"div"}*/}
                          {/*  >*/}
                          {/*    <a*/}
                          {/*      href=""*/}
                          {/*      className="social-list-item bg-info text-white border-info"*/}
                          {/*    >*/}
                          {/*      <i className="mdi mdi-twitter"/>*/}
                          {/*    </a>*/}
                          {/*  </TwitterLogin>*/}
                          {/*</li>*/}

                          {/*<li className="list-inline-item">*/}
                          {/*  <Link*/}
                          {/*    to="#"*/}
                          {/*    className="social-list-item bg-danger text-white border-danger"*/}
                          {/*    onClick={(e) => {*/}
                          {/*      e.preventDefault();*/}
                          {/*      socialResponse("google");*/}
                          {/*    }}*/}
                          {/*  >*/}
                          {/*    <i className="mdi mdi-google" />*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                        </ul>
                      </div>

                      {/*<div className="mt-4 text-center">*/}
                      {/*  /!*<Link to="/forgot-password" className="text-muted">*!/*/}
                      {/*  <Link to="#" className="text-muted">*/}
                      {/*    <i className="mdi mdi-lock me-1" />*/}
                      {/*    Forgot your password?*/}
                      {/*  </Link>*/}
                      {/*</div>*/}
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/*<p>*/}
                {/*  Don&#39;t have an account ?{" "}*/}
                {/*  /!*<Link to="/register" className="fw-medium text-primary">*!/*/}
                {/*  <Link to="#" className="fw-medium text-primary">*/}
                {/*    {" "}*/}
                {/*    Signup now{" "}*/}
                {/*  </Link>{" "}*/}
                {/*</p>*/}
                <p>© {new Date().getFullYear()} Fight Tracker.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
