import {all, fork} from "redux-saga/effects";

import AuthSaga from "./auth/login/saga";
import LayoutSaga from "./layout/saga";

import projectsSaga from "./projects/saga";
import fightsSaga from "./fights/saga";
import watchGetToken from "./auth/get-token/saga";
import fightsGen from "./fights/gen";
import shareResourceGen from "./share-resource/gen";
import notificationsGen from "./notifications/gen";
import emailSubscriptionGen from "./email-subscription/gen";
import {getSagasFork} from "../state-generator/state-generator";

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(projectsSaga),
    fork(fightsSaga),
    fork(watchGetToken),
    ...getSagasFork(
      fightsGen,
      shareResourceGen,
      notificationsGen,
      emailSubscriptionGen,
    ),
  ]);
}
