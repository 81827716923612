import React from "react";
import {Container, Row, Col} from "reactstrap";

//Import Components
import FeatureBox from "./feature-box";

//Import images
import feature1 from "../../../assets/images/crypto/features-img/img-1.png";
import feature2 from "../../../assets/images/crypto/features-img/img-2.png";

const Features = () => {
  const features1 = [
    {id: 1, desc: "One click layout change"},
    {id: 2, desc: "Multiple background images and colors to choose"},
  ];
  const features2 = [
    {id: 1, desc: "Detailed description"},
    {id: 2, desc: "People involved"},
    {id: 3, desc: "Evidence"},
    {id: 4, desc: "Possible resolutions"},
    {id: 5, desc: "Comments"},
  ];

  return (
    <React.Fragment>
      <section className="section" id="features">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">Features</div>
                <h4>Key features of the platform</h4>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center pt-4">
            <Col md="6" sm="8">
              <div>
                <img
                  src="/customize1.png"
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
            <Col md="5" className="ms-auto">
              {/* featurebox */}
              <FeatureBox
                num="01"
                title="Unique Experience"
                features={features1}
                desc="Personalize your experience in our platform with a simple click. Fit your mood with customizable themes."
              />
            </Col>
          </Row>

          <Row className="align-items-center mt-5 pt-md-5">
            <Col md="5">
              {/* featurebox */}
              <FeatureBox
                num="02"
                title="Track every detail"
                features={features2}
                desc="Never miss again any detail of your conflicts, in our platform you can track everything."
              />
            </Col>
            <Col md="6" sm="8" className="ms-md-auto">
              <div className="mt-4 me-md-0">
                <img
                  src="/overview.png"
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
