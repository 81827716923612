import PropTypes from "prop-types";
import React, {useEffect, useRef} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Col, FormFeedback, Input, Row, Spinner} from "reactstrap";
import * as Yup from "yup";
import {GEN_STATUS} from "../../../state-generator/state-generator";

const ShareInviteInput = ({t, onSubmit, formResetFn, status, showSpinner}) => {
  const formikRef = useRef();

  useEffect(() => {
    if (status === GEN_STATUS.SUCCESS && formikRef) {
      formikRef.current?.resetForm();
    }
  }, [status]);
  return (
    <div>
      <Formik
        innerRef={formikRef}
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email()
            .required(t("common.this_is_a_required_field")),
        })}
        onSubmit={onSubmit}
      >
        {({resetForm}) => (
          <Form>
            {(formResetFn = resetForm)}
            <Row>
              <Col md={6}>
                <Field
                  name="email"
                  as={Input}
                  placeholder="name@example.com"
                  type="email"
                />
                <ErrorMessage component={FormFeedback} name={`email`} />
              </Col>
              <Col md={2} style={{textAlign: "left"}}>
                <button type="submit" className="btn btn-primary save-user">
                  {t("share-resource.create")}
                </button>
              </Col>
              <Col md={4} style={{textAlign: "left"}}>
                {(status === GEN_STATUS.LOADING || showSpinner) && (
                  <Spinner animation="border" />
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ShareInviteInput.propTypes = {
  t: PropTypes.any,
};

export default ShareInviteInput;
