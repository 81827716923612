export const base64UrlToBase64 = (base64Url) =>
  base64Url.replace(/\-/g, "+").replace(/_/g, "/") +
  "=".repeat(base64Url.length % 4 ? 4 - (base64Url.length % 4) : 0);
const base64ToArr = (base64) =>
  Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
export const base64UrlToJsonToObj = (base64Url) => {
  if (!base64Url) {
    return base64Url;
  }
  const base64 = base64UrlToBase64(base64Url);
  const arr = base64ToArr(base64);
  const jsonString = new TextDecoder("utf-8").decode(arr);
  return JSON.parse(jsonString);
};
