import {
  RETRIEVE_FIGHTS_DATA,
  RETRIEVE_FIGHTS_DATA_FAIL,
  RETRIEVE_FIGHTS_DATA_SUCCESS,
  UPDATE_FIGHT,
  UPDATE_FIGHT_SUCCESS,
  UPDATE_FIGHT_FAIL,
  DELETE_FIGHT,
  DELETE_FIGHT_FAIL,
  DELETE_FIGHT_SUCCESS,
  CREATE_FIGHT,
  CREATE_FIGHT_SUCCESS,
  CREATE_FIGHT_FAIL,
  CREATE_FIGHT_RESET,
} from "./actions";
import {COMMON_STATUS} from "../../helpers/fights_helper";

const INIT_STATE = {
  error: {},
  isLoading: false,
  fightsSuccess: false,
  fights: {},
  fightsUpdated: 0,
  fightsDeleted: 0,
  createFightStatus: COMMON_STATUS.INITIAL,
};

const fights = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RETRIEVE_FIGHTS_DATA:
      return {
        ...state,
        isLoading: true,
        fightsSuccess: false,
      };
    case RETRIEVE_FIGHTS_DATA_SUCCESS:
      return {
        ...state,
        fights: action.payload,
        isLoading: false,
        fightsSuccess: true,
      };

    case RETRIEVE_FIGHTS_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case CREATE_FIGHT:
      return {
        ...state,
        fightsCreated: 0,
        createFightStatus: COMMON_STATUS.LOADING,
        error: {},
      };

    case CREATE_FIGHT_SUCCESS:
      return {
        ...state,
        fightsCreated: state.fightsCreated + 1,
        createFightStatus: COMMON_STATUS.SUCCESS,
      };

    case CREATE_FIGHT_FAIL:
      return {
        ...state,
        createFightStatus: COMMON_STATUS.FAIL,
        error: action.payload,
      };
    case CREATE_FIGHT_RESET:
      return {
        ...state,
        error: {},
        createFightStatus: COMMON_STATUS.INITIAL,
      };

    case UPDATE_FIGHT:
      return {
        ...state,
        errorUpdate: {},
      };

    case UPDATE_FIGHT_SUCCESS:
      return {
        ...state,
        fightsUpdated: state.fightsUpdated + 1,
      };

    case UPDATE_FIGHT_FAIL:
      return {
        ...state,
        errorUpdate: action.payload,
      };

    case DELETE_FIGHT:
      return {
        ...state,
        payload: action.payload,
      };

    case DELETE_FIGHT_SUCCESS:
      return {
        ...state,
        fightsDeleted: state.fightsDeleted + 1,
        errorUpdate: action.payload,
      };

    case DELETE_FIGHT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default fights;
