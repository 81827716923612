import React, {useState} from "react";
import {Container, Row, Col, Card, CardBody} from "reactstrap";
import {Link} from "react-router-dom";

//Images
import client1 from "../../../assets/images/clients/1.png";
import client2 from "../../../assets/images/clients/2.png";
import client3 from "../../../assets/images/clients/3.png";
import client4 from "../../../assets/images/clients/4.png";
import client5 from "../../../assets/images/clients/5.png";
import client6 from "../../../assets/images/clients/6.png";

//swiper
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination, Navigation, Autoplay} from "swiper";
import "../../../../node_modules/swiper/swiper.scss";

const AboutUs = () => {
  return (
    <React.Fragment>
      <section className="section pt-4 bg-white" id="about">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">About us</div>
                <h4>What is Fight Tracker?</h4>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="5">
              <div className="text-muted">
                <h4>Best tracker for conflicts</h4>
                <p>
                  Visualize any kind of fights happening all around the world,
                  keep track of yours, share privately with your friends or open
                  access to everyone, upload evidence, analyze your conflict
                  with our state of the art technology and render charts with
                  insights.
                </p>
                <p className="mb-4">
                  You would start creating fights all the time.
                </p>

                <div className="d-flex flex-wrap gap-2">
                  <Link
                    to="https://app.swaggerhub.com/apis-docs/zkd/Fights-API/"
                    target={"_blank"}
                    className="btn btn-success"
                  >
                    OpenAPI Spec
                  </Link>
                  <Link
                    to="https://github.com/zakred/fight-tracker-service"
                    target={"_blank"}
                    className="btn btn-outline-primary"
                  >
                    GitHub Repo
                  </Link>
                </div>

                <Row className="mt-4">
                  <Col lg="4" xs="6">
                    <div className="mt-4">
                      <h4> 101 </h4>
                      <p>Figths Created</p>
                    </div>
                  </Col>
                  <Col lg="4" xs="6">
                    <div className="mt-4">
                      <h4>12</h4>
                      <p>Users</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg="6" className="ms-auto">
              <div className="mt-4 mt-lg-0">
                <Row>
                  <Col sm="6">
                    <Card className="border">
                      <CardBody>
                        <div className="mb-3">
                          <i className="mdi mdi-lock-check h2 text-success" />
                        </div>
                        <h5>Access</h5>
                        <p className="text-muted mb-0">
                          RBAC technology to secure your fights
                        </p>
                      </CardBody>
                      <div className="card-footer bg-transparent border-top text-center">
                        <Link
                          to="https://en.wikipedia.org/wiki/Role-based_access_control"
                          className="text-primary"
                        >
                          Learn more
                        </Link>
                      </div>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card className="border mt-lg-5">
                      <CardBody>
                        <div className="mb-3">
                          <i className="mdi mdi-wallet-outline h2 text-success" />
                        </div>
                        <h5>OAuth2 and OIDC Authentication</h5>
                        <p className="text-muted mb-0">
                          Your account is secured with Authorization Code flow
                          with Proof Key for Code Exchange
                        </p>
                      </CardBody>
                      <div className="card-footer bg-transparent border-top text-center">
                        <Link
                          to="https://datatracker.ietf.org/doc/html/rfc7636"
                          className="text-primary"
                        >
                          Learn more
                        </Link>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <hr className="my-5" />
          <Row>
            <Col lg={12}>
              <div className="hori-timeline">
                <Swiper
                  slidesPerView={1}
                  // spaceBetween={10}
                  navigation
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    678: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 3,
                    },
                    1400: {
                      slidesPerView: 4,
                    },
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  modules={[Pagination, Navigation, Autoplay]}
                  className="owl-carousel owl-theme clients-carousel"
                  id="clients-carousel"
                  dir="ltr"
                >
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client1}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client2}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client3}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client4}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  {/*<SwiperSlide className="item">*/}
                  {/*  <div className="client-images">*/}
                  {/*    <img*/}
                  {/*      src={client5}*/}
                  {/*      alt="client-img"*/}
                  {/*      className="mx-auto img-fluid d-block"*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</SwiperSlide>*/}
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client6}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
