import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const DashedLine = ({dataColors}) => {
  const dashedLineChartColors = getChartColorsArray(dataColors);

  const series = [
    {
      name: "Aggressor",
      data: [45, 40, 85, 95, 50],
    },
    {
      name: "Victim",
      data: [36, 90, 60, 35, 56],
    },
  ];
  const options = {
    chart: {zoom: {enabled: !1}, toolbar: {show: !1}},
    colors: dashedLineChartColors,
    dataLabels: {enabled: !1},
    stroke: {width: [3, 4, 3], curve: "straight", dashArray: [0, 8, 5]},
    //title: {text: "", align: "left"},
    markers: {size: 0, hover: {sizeOffset: 6}},
    xaxis: {
      categories: [
        "severity",
        "drama level",
        "hostility",
        "evilness",
        "popularity",
      ],
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (e) {
              return e + " (mins)";
            },
          },
        },
        {
          title: {
            formatter: function (e) {
              return e + " per session";
            },
          },
        },
        {
          title: {
            formatter: function (e) {
              return e;
            },
          },
        },
      ],
    },
    grid: {borderColor: "#f1f1f1"},
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="260"
      className="apex-charts"
    />
  );
};

export default DashedLine;
