import {
  postJsonWithAuthTokenFn,
  putJsonWithAuthTokenFn,
} from "../../helpers/api_helper";
import {
  CREATE_SHARE_RESOURCE_URL,
  DELETE_SHARE_RESOURCE_URL,
  UPDATE_ROLE_SHARE_RESOURCE_URL,
} from "../../helpers/url_helper";

export const CREATE_SHARE_RESOURCE = "CREATE_SHARE_RESOURCE";
export const DELETE_SHARE_RESOURCE = "DELETE_SHARE_RESOURCE";
export const UPDATE_ROLE_SHARE_RESOURCE = "UPDATE_ROLE_SHARE_RESOURCE";
export const SHARE_TYPES = {
  FIGHT: "FIGHT",
  LIST: "LIST",
};

export const SHARE_ROLES = {
  VIEW: "VIEW",
  EDIT: "EDIT",
  EDIT_INVITE: "EDIT_INVITE",
};

const createShareResourceWorker = (payload) => () => {
  return postJsonWithAuthTokenFn(CREATE_SHARE_RESOURCE_URL, payload).then(
    (r) => r.data,
  );
};

const deleteShareResourceWorker = (payload) => () => {
  return postJsonWithAuthTokenFn(DELETE_SHARE_RESOURCE_URL, payload).then(
    (r) => r.data,
  );
};

const updateRoleShareResourceWorker = (payload) => () => {
  return putJsonWithAuthTokenFn(UPDATE_ROLE_SHARE_RESOURCE_URL, payload).then(
    (r) => r.data,
  );
};

export default [
  {action: CREATE_SHARE_RESOURCE, worker: createShareResourceWorker},
  {action: DELETE_SHARE_RESOURCE, worker: deleteShareResourceWorker},
  {action: UPDATE_ROLE_SHARE_RESOURCE, worker: updateRoleShareResourceWorker},
];
