import React from "react";
import PropTypes from "prop-types";
import {map, get} from "lodash";
import {Card, CardBody, Col, Row} from "reactstrap";
import * as fightsHelper from "../../../helpers/fights_helper";
import {fightImageIfNotPresent} from "../../../helpers/fights_helper";

const ProjectDetail = ({fight}) => {
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <img
            src={fightImageIfNotPresent(fight.fightImageUrl)}
            alt=""
            className="avatar-sm me-4"
          />

          <div className="flex-grow-1 overflow-hidden">
            <h5 className="text-truncate font-size-15">{fight.fightName}</h5>
            <p className="text-muted">{fight.description}</p>
          </div>
        </div>

        {/*<h5 className="font-size-15 mt-4">Fight Details:</h5>*/}

        <p className="text-muted">{get(fight, "fight.description")}</p>

        <div className="text-muted mt-4">
          {fight.possibleResolutions && (
            <h5 className="font-size-15 mt-4">Possible resolutions:</h5>
          )}
          {fight.possibleResolutions &&
            map(fight.possibleResolutions, (point, index) => (
              <p key={index}>
                <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                {point}
              </p>
            ))}
        </div>

        <Row className="task-dates">
          <Col sm="4" xs="4">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar me-1 text-primary" /> Start Date
              </h5>
              <p className="text-muted mb-0">
                {new Date(fight.startDate).toDateString()}
              </p>
            </div>
          </Col>

          <Col sm="4" xs="4">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                Expected End Date
              </h5>
              <p className="text-muted mb-0">
                {new Date(fight.expectedEndDate).toDateString()}
              </p>
            </div>
          </Col>

          <Col sm="4" xs="4">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar-check me-1 text-primary" /> End
                Date
              </h5>
              <p className="text-muted mb-0">
                {fight.endDate &&
                  new Date(fight.expectedEndDate).toDateString()}
              </p>
            </div>
          </Col>
        </Row>

        <Row className="task-dates">
          <Col sm="4" xs="4">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-message-rounded-dots me-1 text-info" />{" "}
                Status
              </h5>
              <p className="text-muted mb-0">
                {fight.status && fightsHelper.convertStatus(fight.status)}
              </p>
            </div>
          </Col>

          <Col sm="4" xs="4">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-angry me-1 text-danger" /> Severity
              </h5>
              <p className="text-muted mb-0">
                {fight.severity && fight.severity}
              </p>
            </div>
          </Col>

          <Col sm="4" xs="4">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar-check me-1 text-primary" /> Is
                Remediable?
              </h5>
              <p className="text-muted mb-0">
                {fight.isRemediable ? "\u2713" : "\u274C"}
              </p>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

ProjectDetail.propTypes = {
  fight: PropTypes.object,
};

export default ProjectDetail;
