import {
  GET_TOKEN,
  GET_TOKEN_FAIL,
  GET_TOKEN_SUCCESS,
  STORE_AUTH_TOKEN_FN,
} from "./actions";

const INIT_STATE = {
  errorToken: {},
  isTokenLoading: false,
  token: undefined,
  authTokenFn: false,
};

const authToken = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TOKEN:
      return {
        ...state,
        isTokenLoading: true,
      };
    case GET_TOKEN_SUCCESS:
      return {
        ...state,
        isTokenLoading: false,
        token: action.payload,
      };
    case GET_TOKEN_FAIL:
      return {
        ...state,
        isTokenLoading: false,
        error: action.payload,
      };

    case STORE_AUTH_TOKEN_FN:
      return {
        ...state,
        authTokenFn: action.payload,
      };

    default:
      return state;
  }
};

export default authToken;
