import React, {useEffect, useState} from "react";
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import {connect, useDispatch, useSelector} from "react-redux";
import {
  deleteFight,
  retrieveFightsData,
  updateFight,
} from "../../store/fights/actions";

import PropTypes from "prop-types";
import withRouter from "../../components/Common/withRouter";
import {withTranslation} from "react-i18next";
import DeleteModal from "../../components/Common/DeleteModal";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {map} from "lodash";
import {Link} from "react-router-dom";
import * as moment from "moment/moment";

import {useFormik} from "formik";
import * as Yup from "yup";
import {textShortener} from "../../helpers/text_helper";
import {
  avatarUrlIfNotPresent,
  fightImageIfNotPresent,
  prepareUpdatePayload,
} from "../../helpers/fights_helper";
import ShareResourceModal from "./ShareResource/share-resource-modal";
import {
  CREATE_SHARE_RESOURCE,
  DELETE_SHARE_RESOURCE,
  SHARE_TYPES,
} from "../../store/share-resource/gen";
import {on} from "../../state-generator/state-generator";

const ProjectStatus = ({t, status}) => {
  switch (status) {
    case "NOT_STARTED":
      return <Badge className="bg-info"> {t("Not Started")} </Badge>;

    case "IN_PROGRESS":
      return <Badge className="bg-warning"> {t("In Progress")} </Badge>;

    case "COMPLETED":
      return <Badge className="bg-success"> {t("Completed")} </Badge>;

    default:
      return <Badge className="bg-success"> </Badge>;
  }
};

const FightsList = (props) => {
  document.title = "Fights List";
  const dispatch = useDispatch();
  const {fights, fightsUpdated, fightsDeleted, fightsSuccess, isLoading} =
    useSelector((state) => state.Fights);
  const {authTokenFn} = useSelector((state) => state.authToken);

  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMessage, setDeleteModalMessage] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [fightSelected, setFightSelected] = useState();
  const [shareModal, setShareModal] = useState(false);
  const [roleChangedInShareModal, setRoleChangedInShareModal] = useState(false);

  const onCreateShare = on(CREATE_SHARE_RESOURCE);
  const onDeleteShare = on(DELETE_SHARE_RESOURCE);

  useEffect(() => {
    dispatch(retrieveFightsData());
  }, [
    authTokenFn,
    fightsUpdated,
    fightsDeleted,
    onCreateShare.isSuccess,
    onDeleteShare.isSuccess,
  ]);

  useEffect(() => {
    if (!shareModal && roleChangedInShareModal) {
      dispatch(retrieveFightsData());
    }
  }, [shareModal]);

  useEffect(() => {
    if (fightSelected) {
      const newFight = fights.find((x) => x.fightId === fightSelected.fightId);
      if (newFight) {
        setFightSelected(newFight);
      }
    }
  }, [fightsSuccess]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fightName: (fightSelected && fightSelected.fightName) || "",
      description: (fightSelected && fightSelected.description) || "",
      status: (fightSelected && fightSelected.status) || "",
      fightImageUrl: (fightSelected && fightSelected.fightImageUrl) || "",
      endDate: (fightSelected && fightSelected.endDate) || "",
    },
    validationSchema: Yup.object({
      fightName: Yup.string()
        .max(120, props.t("common.max_characters_number_is") + 120)
        .required(props.t("fights-list.enter_fight_name")),
      description: Yup.string()
        .max(5000, props.t("common.max_characters_number_is") + 5000)
        .required(props.t("fights-list.enter_description")),
      status: Yup.string().required(props.t("fights-list.enter_status")),
      fightImageUrl: Yup.string().max(
        200000,
        props.t("common.max_characters_number_is") + "200kb",
      ),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        let updatedFight = {
          ...fightSelected,
          fightName: values.fightName,
          description: values.description,
          status: values.status,
          fightImageUrl: values.fightImageUrl,
          endDate: values.endDate,
        };

        prepareUpdatePayload(updatedFight);

        const payload = {
          data: updatedFight,
        };
        dispatch(updateFight(payload));
      }
      toggle();
    },
  });
  const toggle = () => {
    if (modal) {
      setModal(false);
      setFightSelected(null);
    } else {
      setModal(true);
    }
  };
  const handleValidDate = (date) => {
    return moment(new Date(date)).format("DD MMM Y");
  };
  const onClickDelete = (ft) => {
    setFightSelected(ft);
    setDeleteModal(true);
  };
  const handleDeleteFight = () => {
    if (fightSelected && fightSelected.fightId) {
      dispatch(deleteFight({data: {fightId: fightSelected.fightId}}));
    }
    setDeleteModal(false);
  };

  useEffect(() => {
    if (fightSelected && fightSelected.fightId) {
      setDeleteModalMessage(
        props.t("common.modal_delete_message") + fightSelected.fightName,
      );
    }
  }, [fightSelected]);

  const handleShareClick = (ft) => {
    setFightSelected({
      ...ft,
    });
    setRoleChangedInShareModal(false);
    setShareModal(true);
  };
  const handleEditClick = (arg) => {
    const ft = arg;

    setFightSelected({
      ...ft,
    });

    setIsEdit(true);

    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteFight}
        t={props.t}
        message={deleteModalMessage}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ShareResourceModal
        show={shareModal}
        resource={fightSelected}
        anyRoleChanged={setRoleChangedInShareModal}
        type={SHARE_TYPES.FIGHT}
        onCloseClick={() => setShareModal(false)}
        t={props.t}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={props.t("Fights")}
            breadcrumbItem={props.t("Fights List")}
          />

          <Row>
            <Col lg="12">
              <div>
                <div className="table-responsive" style={{minHeight: 350}}>
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" style={{width: "100px"}}>
                          #
                        </th>
                        <th scope="col">{props.t("Fights")}</th>
                        <th scope="col">{props.t("Start Date")}</th>
                        <th scope="col">{props.t("Status")}</th>
                        <th scope="col">{props.t("People involved")}</th>
                        <th scope="col">{props.t("common.attributes")}</th>
                        <th scope="col">{props.t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(fights, (fight, index) => (
                        <tr key={index}>
                          <td>
                            <Link to={`/fights-overview/${fight.fightId}`}>
                              <img
                                src={fightImageIfNotPresent(
                                  fight.fightImageUrl,
                                )}
                                alt=""
                                className="avatar-sm"
                              />
                            </Link>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              <Link
                                to={`/fights-overview/${fight.fightId}`}
                                className="text-dark"
                              >
                                {fight.fightName}
                              </Link>
                            </h5>
                            <p className="text-muted mb-0">
                              {textShortener(fight.description)}
                            </p>
                          </td>
                          <td> {handleValidDate(fight.startDate)}</td>
                          <td>
                            <ProjectStatus t={props.t} status={fight.status} />
                          </td>
                          <td>
                            <div className="avatar-group">
                              {fight.personsInvolved &&
                                map(fight.personsInvolved, (person, index) => (
                                  <div
                                    className="avatar-group-item"
                                    key={index}
                                  >
                                    <Link
                                      to="#"
                                      className="team-member d-inline-block"
                                      id={`person-${fight.fightId}-${index}`}
                                    >
                                      <img
                                        src={avatarUrlIfNotPresent(
                                          person.avatarUrl,
                                          person.name,
                                        )}
                                        className="rounded-circle avatar-xs"
                                        alt=""
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={`person-${fight.fightId}-${index}`}
                                      >
                                        {person.name}
                                      </UncontrolledTooltip>
                                    </Link>
                                  </div>
                                ))}
                            </div>
                          </td>
                          <td>
                            <div>
                              {fight.attributes.isShared && (
                                <>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="isshared"
                                  >
                                    {props.t("common.shared")}
                                  </UncontrolledTooltip>
                                  <i
                                    id="isshared"
                                    className="mdi mdi-folder-account font-size-16 text-success me-1"
                                  />
                                </>
                              )}
                              {fight.attributes.canShare && (
                                <>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="canshare"
                                  >
                                    {props.t("common.admin")}
                                  </UncontrolledTooltip>
                                  <i
                                    id="canshare"
                                    className="mdi mdi-account-lock-outline font-size-16 text-danger me-1"
                                  />
                                </>
                              )}
                            </div>
                          </td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="card-drop"
                                tag="div"
                                hidden={
                                  !fight.attributes.canEdit &&
                                  !fight.attributes.canShare &&
                                  !fight.attributes.canDelete
                                }
                                style={{cursor: "pointer"}}
                              >
                                <i className="mdi mdi-dots-horizontal font-size-24" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                  style={{cursor: "pointer"}}
                                  hidden={!fight.attributes.canShare}
                                  onClick={() => handleShareClick(fight)}
                                >
                                  <i className="mdi mdi-share-variant font-size-16 text-info me-1" />{" "}
                                  {props.t("common.share")}
                                </DropdownItem>
                                <DropdownItem
                                  style={{cursor: "pointer"}}
                                  hidden={!fight.attributes.canEdit}
                                  onClick={() => handleEditClick(fight)}
                                >
                                  <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                                  {props.t("common.quick_edit")}
                                </DropdownItem>
                                <DropdownItem
                                  style={{cursor: "pointer"}}
                                  hidden={!fight.attributes.canDelete}
                                  onClick={() => onClickDelete(fight)}
                                >
                                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? props.t("Edit Fight") : props.t("Add Fight")}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <Input
                              type="hidden"
                              value={validation.values.img || ""}
                              name="img"
                            />

                            <Input
                              type="hidden"
                              value={validation.values.team || ""}
                              name="team"
                            />
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("fights-list.fight_name")}
                              </Label>
                              <Input
                                name="fightName"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.fightName || ""}
                                invalid={
                                  validation.touched.fightName &&
                                  validation.errors.fightName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.fightName &&
                              validation.errors.fightName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.fightName}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("fights-list.fight_description")}
                              </Label>
                              <Input
                                name="description"
                                type="textarea"
                                rows="8"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description &&
                                  validation.errors.description
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.description &&
                              validation.errors.description ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.description}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Status</Label>
                              <Input
                                name="status"
                                id="status1"
                                type="select"
                                className="form-select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.status || ""}
                              >
                                <option>NOT_STARTED</option>
                                <option>IN_PROGRESS</option>
                                <option>COMPLETED</option>
                              </Input>
                              {validation.touched.status &&
                              validation.errors.status ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.status}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("fights-list.fight_end_date")}
                              </Label>
                              <Input
                                name="endDate"
                                type="date"
                                format="YYYY/MM/DD"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.endDate &&
                                  validation.errors.endDate
                                    ? true
                                    : false
                                }
                                value={
                                  (validation.values.endDate &&
                                    moment(
                                      new Date(validation.values.endDate),
                                    ).format("yyyy-MM-DD")) ||
                                  ""
                                }
                              ></Input>
                              {validation.touched.endDate &&
                              validation.errors.endDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.endDate}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("fights-list.fight_image_url")}
                              </Label>
                              <Input
                                name="fightImageUrl"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.fightImageUrl || ""}
                                invalid={
                                  validation.touched.fightImageUrl &&
                                  validation.errors.fightImageUrl
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.fightImageUrl &&
                              validation.errors.fightImageUrl ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.fightImageUrl}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                {props.t("common.save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              {isLoading && (
                <div className="text-center my-3 text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  {props.t("common.loading")}...
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

FightsList.propTypes = {
  t: PropTypes.any,
};

export default withRouter(connect(null, {})(withTranslation()(FightsList)));
