const {
  FIGHT_SERVICE_RETURNS_UNAUTHORIZED,
} = require("../../store/auth/failures/actions");
const AuthMiddleware = (storeAPI) => (next) => (action) => {
  if (action.type === FIGHT_SERVICE_RETURNS_UNAUTHORIZED) {
    storeAPI.dispatch({type: "LOGOUT_USER"});
    window.location = "/logout";
  }
  return next(action);
};

module.exports = {
  AuthMiddleware,
};
