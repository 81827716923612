import {put, call, takeLatest} from "redux-saga/effects";
import {GET_TOKEN, GET_TOKEN_SUCCESS, GET_TOKEN_FAIL} from "./actions";

export function* getTokenSaga(action) {
  try {
    const token = yield call(action.payload);
    yield put({type: GET_TOKEN_SUCCESS, payload: token});
  } catch (error) {
    yield put({type: GET_TOKEN_FAIL, payload: error});
  }
}

export default function* watchGetToken() {
  yield takeLatest(GET_TOKEN, getTokenSaga);
}
