import {
  get,
  getWithAuthTokenFn,
  postJsonWithAuthTokenFn,
  putJsonWithAuthTokenFn,
} from "../../helpers/api_helper";
import {put, all, fork, call, takeLatest} from "redux-saga/effects";
import {
  CREATE_FIGHT,
  CREATE_FIGHT_FAIL,
  CREATE_FIGHT_SUCCESS,
  DELETE_FIGHT,
  DELETE_FIGHT_FAIL,
  DELETE_FIGHT_SUCCESS,
  RETRIEVE_FIGHTS_DATA,
  RETRIEVE_FIGHTS_DATA_FAIL,
  RETRIEVE_FIGHTS_DATA_SUCCESS,
  UPDATE_FIGHT,
  UPDATE_FIGHT_FAIL,
  UPDATE_FIGHT_SUCCESS,
} from "./actions";
import {
  CREATE_FIGHT_URL,
  DELETE_FIGHT_URL,
  RETRIEVE_FIGHTS_DATA_URL,
  UPDATE_FIGHT_URL,
} from "../../helpers/url_helper";

export function* fetchFightsDataSaga(action) {
  try {
    const response = yield call(
      getWithAuthTokenFn,
      RETRIEVE_FIGHTS_DATA_URL,
      action.payload,
    );
    yield put({type: RETRIEVE_FIGHTS_DATA_SUCCESS, payload: response.data});
  } catch (error) {
    yield put({type: RETRIEVE_FIGHTS_DATA_FAIL, payload: error});
  }
}

export function* updateFightSaga(action) {
  try {
    const response = yield call(
      putJsonWithAuthTokenFn,
      UPDATE_FIGHT_URL,
      action.payload.data,
    );
    yield put({type: UPDATE_FIGHT_SUCCESS, payload: response.data});
  } catch (error) {
    yield put({type: UPDATE_FIGHT_FAIL, payload: error});
  }
}

export function* createFightSaga(action) {
  try {
    const response = yield call(
      postJsonWithAuthTokenFn,
      CREATE_FIGHT_URL,
      action.payload.data,
    );
    yield put({type: CREATE_FIGHT_SUCCESS, payload: response.data});
  } catch (error) {
    yield put({type: CREATE_FIGHT_FAIL, payload: error});
  }
}

export function* deleteFightSaga(action) {
  try {
    const response = yield call(
      postJsonWithAuthTokenFn,
      DELETE_FIGHT_URL,
      action.payload.data,
    );
    yield put({type: DELETE_FIGHT_SUCCESS, payload: response.data});
  } catch (error) {
    yield put({type: DELETE_FIGHT_FAIL, payload: error});
  }
}

function* watchAll() {
  yield takeLatest(RETRIEVE_FIGHTS_DATA, fetchFightsDataSaga);
  yield takeLatest(UPDATE_FIGHT, updateFightSaga);
  yield takeLatest(DELETE_FIGHT, deleteFightSaga);
  yield takeLatest(CREATE_FIGHT, createFightSaga);
}

function* fightsSaga() {
  yield all([fork(watchAll)]);
}

export default fightsSaga;
