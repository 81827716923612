import {combineReducers} from "redux";

import Layout from "./layout/reducer";
import Login from "./auth/login/reducer";
import projects from "./projects/reducer";
import Fights from "./fights/reducer";
import authToken from "./auth/get-token/reducer";
import fightsGen from "./fights/gen";
import shareResourceGen from "./share-resource/gen";
import notificationsGen from "./notifications/gen";
import emailSubscriptionGen from "./email-subscription/gen";
import {getReducers} from "../state-generator/state-generator";

const rootReducer = combineReducers({
  Fights,
  Layout,
  Login,
  projects,
  authToken,
  ...getReducers(
    fightsGen,
    shareResourceGen,
    notificationsGen,
    emailSubscriptionGen,
  ),
});

export default rootReducer;
