import React from "react";
import {Row, Col} from "reactstrap";

//Import Images
import logolight from "../../../assets/images/logo-light.png";

const FooterLink = () => {
  return (
    <React.Fragment>
      <Row>
        <Col lg="6">
          <div className="mb-4">
            <img src={logolight} alt="" height="20" />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FooterLink;
