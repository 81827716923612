import axios from "axios";

import store from "../store";
import {FIGHT_SERVICE_RETURNS_UNAUTHORIZED} from "../store/auth/failures/actions";
import {errorMessage, sessionExpired} from "./notifications";

const axiosApi = axios.create({});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

async function setAuthConfig(config) {
  const authTokenFn = store.getState().authToken.authTokenFn;
  const token = await authTokenFn();
  if (!config.headers) {
    config.headers = {};
  }
  config.headers.Authorization = `Bearer ${token}`;
}

async function handlerUnauthorized(fn, params) {
  try {
    return await fn(...params);
  } catch (e) {
    if (!e.response) {
      throw e;
    }
    const statusCode = e.response.status;
    if (statusCode === 401) {
      sessionExpired();
      setTimeout(() => {
        store.dispatch({type: FIGHT_SERVICE_RETURNS_UNAUTHORIZED});
      }, 6000);
    } else if (statusCode >= 500) {
      errorMessage(
        "Server is currently unavailable, please try again later.",
        "Internal error",
        {timeOut: 4000, progressBar: false},
      );
    }
    throw e;
  }
}
export async function getWithAuthTokenFn(url, config = {}) {
  await setAuthConfig(config);
  return await handlerUnauthorized(axiosApi.get, [url, {...config}]);
}

export async function putJsonWithAuthTokenFn(url, data, config = {}) {
  await setAuthConfig(config);
  return await handlerUnauthorized(axiosApi.put, [url, data, {...config}]);
}

export async function postJsonWithAuthTokenFn(url, data, config = {}) {
  await setAuthConfig(config);
  return await handlerUnauthorized(axiosApi.post, [url, data, {...config}]);
}
export async function get(url, config = {}) {
  return await axiosApi.get(url, {...config}); //.then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, {...data}, {...config})
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, {...data}, {...config})
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, {...config})
    .then((response) => response.data);
}
