import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {BrowserRouter} from "react-router-dom";
import "./i18n";
import {Provider} from "react-redux";
import {Auth0Provider} from "@auth0/auth0-react";

import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
const redirectUri = window.location.origin + "/login";
const onRedirectCallback = (appState) => {
  window.location.pathname;
};
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH_DOMAIN}
    clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
    cacheLocation="localstorage"
    useRefreshTokens={true}
    onRedirectCallback={onRedirectCallback}
    authorizationParams={{
      redirect_uri: redirectUri,
      audience: process.env.REACT_APP_AUTH_AUD.split(","),
      scope: process.env.REACT_APP_AUTH_SCOPES,
    }}
  >
    <Provider store={store}>
      <React.Fragment>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.Fragment>
    </Provider>
    ,
  </Auth0Provider>,
);

serviceWorker.unregister();
