import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

import {Row, Col, CardBody, Card, Container, Input, Spinner} from "reactstrap";

import {Link, useSearchParams} from "react-router-dom";
import withRouter from "components/Common/withRouter";

import logo from "assets/images/logo.svg";
import {base64UrlToJsonToObj} from "../../helpers/encoder_helper";
import {UPDATE_EMAIL_SUBSCRIPTION} from "../../store/email-subscription/gen";
import {on} from "../../state-generator/state-generator";
import Footer from "../../components/HorizontalLayout/Footer";

const Login = (props) => {
  document.title = "Email Subscription";

  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [selected, setSelected] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(undefined);
  const [base64UrlData, setBase64UrlData] = useState(undefined);

  const onUpdateEmailSubscription = on(UPDATE_EMAIL_SUBSCRIPTION);

  const setOptionSelected = (e) => {
    setSelected(e.target.value);
  };

  const getDataFromParams = () => {};

  useEffect(() => {
    if (searchParams.get("data")) {
      const base64Url = searchParams.get("data");
      setBase64UrlData(base64Url);
      setData(base64UrlToJsonToObj(base64Url));
    }
  }, []);

  useEffect(() => {
    if (onUpdateEmailSubscription.isFail) {
      setError(
        "There was an error updating your preference, please try again later.",
      );
      setSuccessMessage(undefined);
    }
    if (onUpdateEmailSubscription.isSuccess) {
      setError(undefined);
      setSuccessMessage("Preference saved successfully.");
    }
  }, [onUpdateEmailSubscription.status()]);

  const submitPreference = () => {
    if (!selected) {
      setError("Select an option");
      setSuccessMessage(undefined);
      return;
    }
    const payload = {
      data: base64UrlData,
      decision: selected,
    };
    onUpdateEmailSubscription.trigger(payload);
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <h6 className="text-primary" style={{textAlign: "center"}}>
              Unsubscribe
            </h6>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={12}></Col>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h4 className="text-primary">Email subscription</h4>
                        <h5>{data && data.category}</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end"></Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div
                        style={{paddingLeft: "20px"}}
                        onChange={setOptionSelected.bind(this)}
                      >
                        <table>
                          <tbody>
                            <tr>
                              <td style={{paddingBottom: 10}}>
                                <Input
                                  type="radio"
                                  name="subscription"
                                  value="SUBSCRIBED"
                                />
                                Yes, sign me up.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Input
                                  type="radio"
                                  name="subscription"
                                  value="UNSUBSCRIBED"
                                />
                                No, thanks.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div style={{paddingTop: 20, paddingLeft: 40}}>
                      {onUpdateEmailSubscription &&
                        onUpdateEmailSubscription.isLoading && (
                          <Spinner animation="border" />
                        )}
                    </div>

                    <div style={{paddingTop: 20, paddingLeft: 40}}>
                      <span className="text-success">
                        {successMessage && successMessage}
                      </span>
                    </div>

                    <div style={{paddingTop: 20, paddingLeft: 40}}>
                      <span className="text-danger">{error && error}</span>
                    </div>
                    <div style={{padding: (0, 0, 0, 40)}}>
                      <button
                        type="submit"
                        className="btn btn-primary save-user"
                        style={{width: "100%"}}
                        onClick={submitPreference}
                      >
                        Save
                      </button>
                    </div>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <span className="font-size-10">
                      Changing your email subscription preference will only
                      affect the subscription for the email you changed (i.e.
                      changes to {data && data.category}) you will continue to
                      receive emails regarding your account and services.
                    </span>
                  </div>
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2"></div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
