import toastr from "toastr";
import "toastr/build/toastr.min.css";

const defaultOptions = {
  positionClass: "toast-top-full-width",
  timeOut: 5000,
  extendedTimeOut: 1000,
  closeButton: true,
  debug: false,
  progressBar: true,
  preventDuplicates: true,
  newestOnTop: false,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  showDuration: 300,
  hideDuration: 1000,
};
export const sessionExpired = () => {
  toastr.options = defaultOptions;
  toastr.error("Your session has expired.", "Unauthorized");
};

export const errorMessage = (message, title, opts = {}) => {
  toastr.options = {...defaultOptions, ...opts};
  toastr.error(message, title);
};

export const errorTopRight = (
  message,
  title,
  opts = {timeout: 10000, progressBar: false, positionClass: "toast-top-right"},
) => {
  toastr.options = {...defaultOptions, ...opts};
  toastr.error(message, title);
};

export const successTopRight = (
  message,
  title,
  opts = {progressBar: false, positionClass: "toast-top-right"},
) => {
  toastr.options = {...defaultOptions, ...opts};
  toastr.success(message, title);
};
