import React, {useEffect, useMemo, useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import {useDispatch} from "react-redux";

import PropTypes from "prop-types";
import TableContainer from "../../../components/Common/TableContainer";
import {Email, Name, Role, Status, Tags} from "./share-resource-col";
import {Link} from "react-router-dom";

import ShareInviteInput from "./share-resource-invite";
import {
  CREATE_SHARE_RESOURCE,
  DELETE_SHARE_RESOURCE,
  SHARE_ROLES,
  SHARE_TYPES,
  UPDATE_ROLE_SHARE_RESOURCE,
} from "../../../store/share-resource/gen";
import {textShortener} from "../../../helpers/text_helper";
import {GEN_STATUS, on} from "../../../state-generator/state-generator";
import {errorTopRight, successTopRight} from "../../../helpers/notifications";
import Avatar from "react-avatar";
import {getDomainError} from "../../../helpers/fights_helper";

const ShareResourceModal = ({
  t,
  show,
  onCloseClick,
  resource,
  type,
  anyRoleChanged,
}) => {
  const onCreateShare = on(CREATE_SHARE_RESOURCE);
  const onDeleteShare = on(DELETE_SHARE_RESOURCE);
  const onUpdateRoleShare = on(UPDATE_ROLE_SHARE_RESOURCE);

  const updateRoleShare = (role, email) => {
    let resourceId = resource.fightId;
    const payload = {
      resourceId: resourceId,
      type: type,
      persons: [
        {
          email: email,
          role: role,
        },
      ],
    };

    onUpdateRoleShare.trigger(payload);
  };
  const createShare = (values) => {
    let resourceId = resource.fightId;

    if (type === SHARE_TYPES.LIST) {
      resourceId = resource.listId;
    }
    const payload = {
      resourceId: resourceId,
      type: type,
      persons: [
        {
          email: values.email,
          role: "VIEW",
        },
      ],
    };
    onCreateShare.trigger(payload);
  };

  const deleteShare = (row) => {
    let resourceId = resource.fightId;

    if (type === SHARE_TYPES.LIST) {
      resourceId = resource.listId;
    }
    const payload = {
      resourceId: resourceId,
      type: type,
      persons: [
        {
          email: row.email,
          role: "VIEW",
        },
      ],
    };

    onDeleteShare.trigger(payload);
  };

  useEffect(() => {
    if (onUpdateRoleShare.isSuccess) {
      anyRoleChanged(true);
    }
  }, [onUpdateRoleShare.status()]);
  useEffect(() => {
    if (onCreateShare.isSuccess) {
      let msg = t("share-resource.created_success");
      successTopRight(msg, t("common.success"));
      onCreateShare.reset();
    } else if (onCreateShare.isFail) {
      let msg = t("common.error_message_generic");
      if (onCreateShare.error()) {
        msg = getDomainError(onCreateShare.error());
      }
      errorTopRight(msg, t("common.error"));
      onCreateShare.reset();
    }
  }, [onCreateShare.status()]);

  useEffect(() => {
    if (onDeleteShare.isSuccess) {
      let msg = t("share-resource.deleted_success");
      successTopRight(msg, t("common.success"));
      onDeleteShare.reset();
    } else if (onDeleteShare.isFail) {
      let msg = t("common.error_message_generic");
      if (onDeleteShare.error()) {
        msg = getDomainError(onDeleteShare.error());
      }
      errorTopRight(msg, t("common.error"));
      onDeleteShare.reset();
    }
  }, [onDeleteShare.status()]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Img",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {cellProps.email && (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  <Avatar
                    round="12px"
                    name={
                      cellProps.email.charAt(0) +
                      " " +
                      cellProps.email.charAt(1)
                    }
                    size="32"
                  />
                </span>
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: (cellProps) => {
          return <Email {...cellProps} />;
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <Status {...cellProps} />;
      //   },
      // },
      {
        Header: "Role",
        accessor: "role",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <>
              {" "}
              {cellProps.row.values.role ? (
                <div>
                  <Input
                    type="select"
                    value={cellProps.row.values.role}
                    onChange={(e) => {
                      updateRoleShare(
                        e.target.value,
                        cellProps.row.values.email,
                      );
                      cellProps.row.values.role = e.target.value;
                    }}
                  >
                    <option value={SHARE_ROLES.VIEW}>{t("common.view")}</option>
                    <option value={SHARE_ROLES.EDIT}>{t("common.edit")}</option>
                    <option value={SHARE_ROLES.EDIT_INVITE}>
                      {t("common.admin")}
                    </option>
                  </Input>
                </div>
              ) : (
                <div>N/A</div>
              )}{" "}
            </>
          );
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  deleteShare(userData);
                }}
              >
                <i
                  className="mdi mdi-close-octagon font-size-18"
                  id="deletetooltip"
                />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {t("share-resource.delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [resource],
  );

  return (
    <Modal size="lg" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          {type === SHARE_TYPES.FIGHT && (
            <h5>
              {t("share-resource.share_fight")}{" "}
              {resource && textShortener(resource.fightName, 45)}
            </h5>
          )}
          {type === SHARE_TYPES.LIST && (
            <h5>{t("share-resource.share_list")}</h5>
          )}
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>

          {/*START*/}

          <Row className="p-4">
            <ShareInviteInput
              t={t}
              onSubmit={createShare}
              status={onCreateShare.status()}
              showSpinner={onUpdateRoleShare.status() === GEN_STATUS.LOADING}
            />
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={resource?.accessControl?.sharedWith}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    customPageSize={5}
                    className="custom-header-css"
                  />

                  {/*<Modal isOpen={modal} toggle={toggle}>*/}
                  {/*  <ModalHeader toggle={toggle} tag="h4">*/}
                  {/*    {!!isEdit ? "Edit User" : "Add User"}*/}
                  {/*  </ModalHeader>*/}
                  {/*  <ModalBody>*/}

                  {/*      <Row>*/}
                  {/*        <Col>*/}

                  {/*        </Col>*/}
                  {/*      </Row>*/}

                  {/*  </ModalBody>*/}
                  {/*</Modal>*/}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/*END*/}
        </ModalBody>
      </div>
    </Modal>
  );
};

ShareResourceModal.propTypes = {
  t: PropTypes.any,
  type: PropTypes.any,
  resource: PropTypes.any,
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default ShareResourceModal;
