import React from "react";
import {Container, Row} from "reactstrap";

//Import Components
import CardBox from "./card-box";

const CardsMini = () => {
  const coins = [
    {
      title: "Fights Created",
      color: "warning",
      icon: "mdi mdi-emoticon-excited-outline",
      value: "101",
      rate: "( 42 % )",
      isIncrease: true,
    },
    {
      title: "People Involved",
      color: "primary",
      icon: "mdi mdi-human-capacity-increase",
      value: "245",
      rate: "( 172 % )",
      isIncrease: true,
    },
    {
      title: "Fights Shared",
      color: "info",
      icon: "mdi mdi-export-variant",
      value: "4",
      rate: " ( 12 % ) ",
      isIncrease: false,
    },
  ];

  return (
    <React.Fragment>
      <section className="section bg-white p-0">
        <Container>
          <div className="currency-price">
            <Row>
              {/* reder card boxes */}
              <CardBox coins={coins} />
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CardsMini;
