import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import {Link} from "react-router-dom";
import {map} from "lodash";
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
  getFormattedErrorMessage,
  isoToLocalPretty,
} from "../../../helpers/fights_helper";
import Avatar from "react-avatar";
import {CREATE_COMMENT, DELETE_COMMENT} from "../../../store/fights/gen";
import {on} from "../../../state-generator/state-generator";
import {errorTopRight} from "../../../helpers/notifications";

const Comments = ({t, comments, fightId}) => {
  const [messageBox, setMessageBox] = useState(null);
  const [currentMessage, setCurrentMessage] = useState("");

  const onCreateComment = on(CREATE_COMMENT);
  const onDeleteComment = on(DELETE_COMMENT);
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      createComment();
    }
  };

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
    }
  };

  useEffect(() => {
    if (comments && comments.length > 0) {
      scrollToBottom();
    }
  }, [comments]);

  useEffect(() => {
    if (onCreateComment.isSuccess) {
      setCurrentMessage("");
    }
  }, [onCreateComment.isSuccess]);

  useEffect(() => {
    if (onCreateComment.isFail || onDeleteComment.isFail) {
      let failedEvent = onCreateComment;
      if (onDeleteComment.isFail) {
        failedEvent = onDeleteComment;
      }
      let error = failedEvent.error();
      const message = getFormattedErrorMessage(error, t);
      errorTopRight(message, t("common.error"));
      failedEvent.reset();
    }
  }, [onCreateComment.isFail, onDeleteComment.isFail]);
  const createComment = () => {
    const payload = {
      fightId: fightId,
      message: currentMessage,
    };
    onCreateComment.trigger(payload);
  };
  const deleteComment = (commentId) => {
    const payload = {
      commentId: commentId,
    };
    onDeleteComment.trigger(payload);
  };

  return (
    <Card>
      <CardBody>
        <div className="chat-conversation p-3">
          <ul className="list-unstyled">
            <PerfectScrollbar
              style={{height: "240px"}}
              containerRef={(ref) => setMessageBox(ref)}
            >
              <li>
                <div className="chat-day-title">
                  <span className="title">{t("common.today")}</span>
                </div>
              </li>
              {comments &&
                map(comments, (message) => (
                  <li key={message.commentId} className={message.side}>
                    <div className="conversation-list">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          style={{cursor: "pointer"}}
                          hidden={!message.canDelete || message.isDeleted}
                          tag="div"
                          className="dropdown-toggle"
                        >
                          <i className="bx bx-dots-vertical-rounded" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={(e) => deleteComment(message.commentId)}
                            style={{cursor: "pointer"}}
                          >
                            {t("common.delete")}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <div className="ctext-wrap">
                        <div className="conversation-name">
                          {message.avatarUrl ? (
                            <img
                              className="media-object rounded-circle avatar-xs"
                              alt=""
                              src={message.avatarUrl}
                            />
                          ) : (
                            <Avatar
                              round="12px"
                              name={
                                message.name.charAt(0) +
                                " " +
                                message.name.charAt(1)
                              }
                              size="32"
                            />
                          )}

                          {message.name}
                        </div>
                        <p>{message.message}</p>
                        <p className="chat-time mb-0">
                          <i className="bx bx-time-five align-middle me-1"></i>{" "}
                          {isoToLocalPretty(message.createdAt)}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
            </PerfectScrollbar>
          </ul>
        </div>
        <div className="p-3 chat-input-section">
          <Row>
            <Col>
              <div className="position-relative">
                <Input
                  type="text"
                  onKeyPress={onKeyPress}
                  onChange={(e) => {
                    setCurrentMessage(e.target.value);
                  }}
                  className="form-control chat-input"
                  placeholder={t("common.enter_message")}
                  maxLength={300}
                  value={currentMessage}
                />
                <div className="chat-input-links">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <Link to="#">
                        <i
                          className="mdi mdi-emoticon-happy-outline me-1"
                          id="Emojitooltip"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="Emojitooltip"
                        >
                          {t("common.emojis")}
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i
                          className="mdi mdi-file-image-outline me-1"
                          id="Imagetooltip"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="Imagetooltip"
                        >
                          {t("common.images")}
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i
                          className="mdi mdi-file-document-outline"
                          id="Filetooltip"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="Filetooltip"
                        >
                          {t("common.add_files")}
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col className="col-auto">
              <Button
                type="button"
                color="primary"
                id="button-send"
                onClick={createComment}
                className="btn btn-primary btn-rounded chat-send w-md "
              >
                <span className="d-none d-sm-inline-block me-2">
                  {t("common.send")}
                </span>{" "}
                <i className="mdi mdi-send" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="col-auto">
              <div className="" style={{width: "100%"}}>
                <span className="font-size-11" style={{paddingLeft: 10}}>
                  {currentMessage ? currentMessage.length : 0}/300
                </span>
              </div>
            </Col>
            <Col>
              <div className="align" style={{paddingTop: 2}}>
                {(onCreateComment.isLoading || onDeleteComment.isLoading) && (
                  <Spinner
                    animation="border"
                    variant="primary"
                    style={{width: "0.8rem", height: "0.8rem"}}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

Comments.propTypes = {
  comments: PropTypes.array,
  t: PropTypes.any,
  fightId: PropTypes.any,
};

export default Comments;
